import { useState } from "react";
import { MeditationCategory, MeditationConfig, VoiceOption, meditationConfigToScriptPrompt } from "../MeditationDesigner/MeditationConfig";
import { getSingleChatCompletion } from "../../lib/GPTAPI";

export const createMeditationConfigs = (
    samples: number,
    goals: Set<MeditationCategory>,
    entries: Set<string | null>,
    durations: Set<number>,
    themes: Set<string | null>,
    username: string,
) => {
     
    let meditationConfigs: MeditationConfig[] = [];

    goals.forEach(goal => {
        
        entries.forEach(entry => {
            console.log("hi");
            durations.forEach(duration => {
                themes.forEach(theme => {
                    for (var i = 0; i < samples; i++) {
                        
                        meditationConfigs.push({
                            uid: undefined,
                            username: username,
                            category: goal,
                            inputText: entry !== null ? entry : undefined,
                            duration: duration,
                            theme: theme,
                            voice: VoiceOption.Saguna
                        })
                    }
                })
            })

        });
    });

    return meditationConfigs;
}

export type ScriptSample = {
    prompt: string;
    config: MeditationConfig;
    script: string;
}

export const getBatchScripts = (
    meditationConfigs: MeditationConfig[],
    onScriptGenerated: (script: ScriptSample) => void
) => {

    meditationConfigs.forEach(meditationConfig => {
        const prompt = meditationConfigToScriptPrompt(meditationConfig);
        getSingleChatCompletion(prompt)
            .then(script => {
                if (script) {
                    onScriptGenerated({
                        prompt: prompt,
                        config: meditationConfig,
                        script: script
                    })   
                }
            })
            .catch(err => {
                console.log(err);   
            })
    });
}

export const CSVHeader = () => {
    return `username,category,inputText,duration,theme,voice,prompt,script\n`
}

export const ScriptSampleToCSVRow = (scriptSample: ScriptSample) => {
    return `${scriptSample.config.username},${scriptSample.config.category},"${escapeString(scriptSample.config.inputText)}",`
        + `${scriptSample.config.duration},${scriptSample.config.theme},${scriptSample.config.voice},` +
        `"${escapeString(scriptSample.prompt)}","${escapeString(scriptSample.script)}"`
}

const escapeString = (str: string | undefined) => {
    if (str) {
        return str.replaceAll(`"`, `""`)
    } else {
        return str;
    }
}

export const ScriptSamplesToCSV = (scriptSamples: ScriptSample[]) => {
    return CSVHeader() + scriptSamples.map(scriptSample => ScriptSampleToCSVRow(scriptSample)).join("\n");
}