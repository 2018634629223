import { updateDoc, doc, increment } from "firebase/firestore";
import { meditationConverter } from "./useMeditation";
import { getFirestore } from 'firebase/firestore';

export const submitFeedback = async (
  uid: string,
  meditationDocId: string,
  thumbsUp: boolean
) => {
  const db = getFirestore();
  const ref = doc(
    db,
    "users",
    uid,
    "meditations",
    meditationDocId
  ).withConverter(meditationConverter);

  await updateDoc(ref, {
    [thumbsUp ? "thumbsUp" : "thumbsDown"]: increment(1),
  });
};
