import { useContext } from "react";
import { AuthContext } from "../App";
import { PrimaryButton } from "../components/UI";
import { useNavigate } from "react-router";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";

export const Account = () => {
    
    const {currentUser} = useContext(AuthContext);
    const navigate = useNavigate();

    return <div className="max-w-[600px] mx-4 sm:mx-auto flex flex-col space-y-8 min-h-[100vh]">
        <div className="text-lg sm:text-4xl font-garamond mt-24"> 
            {currentUser.email}
        </div>
        <PrimaryButton onClick={() => {navigate("/whatsyourname")}}>Change User Name</PrimaryButton>
        <PrimaryButton onClick={
            () => {
                signOut(auth)
                    .then(() => {
                        console.log("sign out successful")
                        navigate("/");
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }>Logout</PrimaryButton>
    </div>
}