import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { addDoc, collection, getDoc, getDocs } from "firebase/firestore";


export const useProducts = (): [boolean, any[]] => {

    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState<any[]>([]);

    useEffect(() => {
        getDocs(collection(db, "stripeproducts"))
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    console.log(doc.id);
                    console.log(doc.data());
                    // @ts-ignore
                    //setProducts(products => [...products, {id: doc.id, ...doc.data()}])
                    
                    //console.log(doc.id, " => ", doc.data());

                    getDocs(collection(db, "stripeproducts", doc.id, "prices"))
                        .then((querySnapshot) => {
                            let prices:any = [];
                            querySnapshot.forEach((doc) => {
                                console.log(doc.id);
                                console.log(doc.data());
                                prices.push({id: doc.id, ...doc.data()});
                            });
                            setProducts(products => [...products, {
                                id: doc.id,
                                ...doc.data(),
                                prices: prices
                            }])
                        });
                });
            });
    }, []);
    

    return [loading, products]
    
}

export const getCheckoutURL = async (uid: string, priceid: string) => {
    console.log(uid);

    const docRef = await addDoc(collection(db, "users", uid, "checkout_sessions"), 
        {
            price: priceid,
            success_url: window.location.origin,
            cancel_url: window.location.origin
        }
    );

    const snap = await getDoc(docRef);
    console.log(snap.data());


}