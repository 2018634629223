import { useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import { AuthContext } from "../App";
import { AccountCircle } from "./icons";
import { PrimaryButton } from "./UI";

export const Navbar = () => {

    const navigate = useNavigate();
    const {currentUser} = useContext(AuthContext)
    const location = useLocation();

    if (location.pathname === '/') return <div/>

    return <div className="relative w-full px-8 flex flex-row items-center justify-between py-4 bg-white z-10">
                <div className="font-quicksand text-xl font-bold px-2 cursor-pointer"
                    onClick={() => {navigate('/home')}}>
                    SIT
                </div>
                <Account currentUser={currentUser}/>
        </div>
}

const Account = (
    {
        currentUser
    } : {
        currentUser: any
    }
) => {
    const navigate = useNavigate();
    const location = useLocation();

    if (currentUser) {
        if (location.pathname === '/') {
            if (process.env.REACT_APP_WAITLIST_MODE === 'TRUE') {
                return <div className="flex flex-row space-x-4">
                    <PrimaryButton dark onClick={() => {<link href="https://tazs7mxpndu.typeform.com/to/cxJTFPuZ"></link>}}>Get Early Access</PrimaryButton>
                </div>
            } else return <PrimaryButton dark onClick={() => {navigate('/home')}}>Go To App</PrimaryButton>
        } else {
            return <div className="cursor-pointer flex flex-row space-x-1 hover:underline"
                    onClick={() => {navigate('/account')}}>
                <div>{AccountCircle}</div>
                <div>{currentUser.displayName}</div>
            </div>
        }
        
    }
    else {
        if (process.env.REACT_APP_WAITLIST_MODE === 'TRUE') {
            return <div className="flex flex-row space-x-4">
                <PrimaryButton dark onClick={() => {<link href="https://tazs7mxpndu.typeform.com/to/cxJTFPuZ"></link>}}>Get Early Access</PrimaryButton>
            </div>
        }
        else return <div className="flex flex-row space-x-4">
            <PrimaryButton onClick={() => {navigate('/login')}}>Login</PrimaryButton>
            <PrimaryButton dark onClick={() => {navigate('/signup')}}>Sign Up</PrimaryButton>
        </div>
    }

}