import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Chip, PrimaryButton } from "../UI"
import { THEME_OPTIONS } from "./MeditationConfig"
import { AuthContext } from '../../App';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { addDoc, collection, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { ThreeDots } from '../icons';

export const ThemePicker = (
    {
        theme,
        setTheme,
        onDone,
    } : {
        theme: string | null,
        setTheme: (theme: string | null) => void,
        onDone: () => void
    }
) => {

    const {currentUser} = useContext(AuthContext);

    const [customTheme, setCustomTheme] = useState<string>("");
    const [randomThemes, setRandomThemes] = useState<string[]>([]);
    const [initialThemes, setInitialThemes] = useState<string[]>([]);

    const [tab, setTab] = useState<"My Themes" | "Recommended Themes">("Recommended Themes");

    const [myThemes, loading, error, myThemesSnapshot] = useCollectionData(collection(db, "users", currentUser.uid, "themes"));

    useEffect(() => {
        const shuffledOptions: string[] = [...THEME_OPTIONS].sort(() => Math.random() - 0.5);
        const selectedThemes = shuffledOptions.slice(0, 3)
        setRandomThemes(selectedThemes);
        setInitialThemes(selectedThemes);
    }, []);

    const handleThemeOptionClick = (themeOption: string | null) => {
        setTheme(themeOption);
    }

    const addCustomTheme = (customTheme: string) => {
        addDoc(collection(db, "users", currentUser.uid, "themes"), {title: customTheme})
        setTheme(customTheme);
    }

    const deleteTheme = (themeId: string) => {
        deleteDoc(doc(db, "users", currentUser.uid, "themes", themeId));
        setTheme(null);
    }

    return (
        <div className="flex flex-col space-y-8 items-center">
            <div className="w-full text-center text-5xl font-garamond">
                Meditation can take you places.<br />
            </div>
            <div className="w-full text-center text-xl font-garamond">
                Choosing a setting will tailor your meditation to a particular place.
            </div>
            <div className="flex flex-row space-x-4">
                <div className={`cursor-pointer border-neutral-700 ${tab === "Recommended Themes" ? "border-b font-bold"  : ""}`}
                    onClick={() =>{setTab("Recommended Themes")}}>Recommended Themes</div>
                <div className={`cursor-pointer border-neutral-700 ${tab === "My Themes" ? "border-b font-bold"  : ""}`}
                    onClick={() => {setTab("My Themes")}}>My Themes</div>
            </div>

            {tab === "Recommended Themes" &&
                <div className="w-3/4 flex flex-col space-y-2">
                    {randomThemes.map((themeOption, index) => 
                        <Chip 
                            key={index}
                            selected={themeOption === theme}
                            onClick={() => handleThemeOptionClick(themeOption)}
                            blinkBeforeClick>
                                <div>
                                    <div className="text-xs">{initialThemes.find((item) => item === themeOption) ? "RECOMMENDED" : "CUSTOM"}</div>
                                </div>
                                {themeOption}
                        </Chip>
                    )}
                </div>
            }      

            {tab === "My Themes" &&
            
                <div className="flex flex-col justify-center space-y-2">
                    {myThemes && myThemes.map((themeOption, index) => 
                        <div className="relative">
                            <Chip 
                                key={index}
                                selected={themeOption.title === theme}
                                onClick={() => handleThemeOptionClick(themeOption.title)}
                                blinkBeforeClick>
                                    <div>
                                        <div className="text-xs">{initialThemes.find((item) => item === themeOption.title) ? "RECOMMENDED" : "CUSTOM"}</div>
                                    </div>
                                    {themeOption.title}
                            </Chip>
                            <div className="absolute left-[103%] top-[25%]">
                                <CustomThemeMenu deleteTheme={() => {deleteTheme(myThemesSnapshot ? myThemesSnapshot.docs[index].id : "")}}/>
                            </div>
                            
                        </div>
                    )}

                    <AddCustom customTheme={customTheme} setCustomTheme={setCustomTheme} addCustomTheme={() => {addCustomTheme(customTheme)}}/>
                </div>

            }
            <div className="w-3/4 flex flex-col space-y-2">
                
                <div className="flex flex-row space-x-2 justify-center items-center">
                    
                </div>
            </div>

            <div className="w-full flex flex-row space-x-12 justify-center">
                <button className="px-8 py-2 cursor-pointer underline decoration-transparent hover:decoration-black transition-all duration-500" 
                onClick={() => {setTimeout(onDone, 400)
                            }
                    }>
                    Skip
                </button>
                <PrimaryButton disabled={theme === null} onClick={onDone}>
                    Continue
                </PrimaryButton>
            </div>
        </div>
    );
}


const AddCustom = (
    {
        customTheme,
        setCustomTheme,
        addCustomTheme,
    } : {
        customTheme: string,
        setCustomTheme: (customTheme: string) => void,
        addCustomTheme: () => void,
    }
) => {

    const [editCustom, setEditCustom] = useState<boolean>(false);

    const addTheme = () => {
        addCustomTheme();
        setEditCustom(false);
    }

    return <div className="w-full flex flex-col items-center relative">
        {!editCustom && <Chip 
            selected={editCustom}
            onClick={() => {setEditCustom(true)}}>
                + Add Custom
        </Chip> }
        <div className={`flex flex-col items-center overflow-hidden ${editCustom ? "max-h-auto" : "max-h-0"}`}>
            <div className={`${editCustom ? "max-w-[100%] opacity-100" : "max-w-[0%] opacity-0"} 
                    overflow-hidden w-full py-4 border border-black rounded-full flex flex-row items-center justify-center space-x-4
                    transition-all duration-1000`}>
                <input type="text" 
                    className={`w-2/3 ml-8 bg-transparent border-b border-black
                        text-xl`}
                    value={customTheme}
                    onChange={(e) => {setCustomTheme(e.target.value)}}
                    placeholder="e.g. New York on a sunny day"/>
                <div className="cursor-pointer hover:underline"
                    onClick={addTheme}>
                    Add
                </div>
                <div className="pr-8 cursor-pointer hover:underline"
                    onClick={() => {setEditCustom(false)}}>
                    Cancel
                </div>
            </div>
        </div>
    </div>
}

const CustomThemeMenu = (
    {
        deleteTheme,
    } : {
        deleteTheme: () => void,
    }
) => {
    const [showMenu, setShowMenu] = useState<boolean>(false);

    return <div className="relative">
        <div key="delete" className="p-1 rounded-full cursor-pointer hover:bg-black/20 fill-neutral-500"
            onClick={() => {setShowMenu(showMenu => !showMenu)}}>
            {ThreeDots}
        </div>
        <div className={`absolute text-sm bg-white/40  rounded-lg flex flex-col top-[100%] left-0 overflow-hidden ${showMenu ? "max-h-auto" : "max-h-0"}`}>
            <div className="p-2 hover:bg-black/20 cursor-pointer"
                onClick={() => {deleteTheme(); setShowMenu(false)}}>
                Delete
            </div>
        </div>
    </div>
}