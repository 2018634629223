import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../App";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { PrimaryButton } from "../components/UI";
import { useNavigate } from "react-router";
import { useUpdateProfile } from "react-firebase-hooks/auth";

export const WhatsYourName = () => {

    const { currentUser } = useContext(AuthContext);
    const [updateProfile, updating, error] = useUpdateProfile(auth);

    const navigate = useNavigate();

    const [username, setUsername] = useState<string>("");

    const saveUsernameAndGoHome = useCallback(() => {
        updateProfile({displayName: username})
            .then(()=> {
                navigate("/home")
            })
            .catch((error) => {
                console.log(error);
            });
    }, [username]);

    return <div className="max-w-[400px] mx-auto min-h-[100vh]">
        <div className="text-5xl font-garamond mt-24 mb-8">What's your name?</div>
        <div className="text-lg">
            This is how we'll refer to you in your meditations. Feel free to use a nickname or a pseudonym.
        </div>

        <input 
            type="text" 
            className="w-full bg-white/50 border border-black/40 mt-8 mb-8 px-4 py-2 text-lg rounded-lg focus:outline-none focus:border-black" 
            value={username}
            placeholder={(currentUser && currentUser.displayName) ? currentUser.displayName : ""}
            onChange={(e) => {setUsername(e.target.value)}} />

        <PrimaryButton 
            onClick={saveUsernameAndGoHome}
            disabled={username.length <= 1}>
            <div>submit</div>
        </PrimaryButton>

    </div>
}