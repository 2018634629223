import { PrimaryButton } from "../UI"
import { useState } from "react";
import { MeditationConfig, MEDITATION_EXPLAIN, MEDITATION_QUESTION, MEDITATION_EXAMPLE } from "./MeditationConfig"

export const TextInput = (
    {
        inputText,
        setInputText,
        chosenMeditation,
        onDone,
    } : {
        inputText: string,
        setInputText: (text: string) => void,
        chosenMeditation: MeditationConfig,
        onDone: () => void,
    }
) => {
    const [showExplain, setShowExplain] = useState(false);

    const question = chosenMeditation.category ? MEDITATION_QUESTION[chosenMeditation.category] : "What's on your mind today?"; 
    const explain = chosenMeditation.category ? MEDITATION_EXPLAIN[chosenMeditation.category] : "What's on your mind today?"; 
    const example = chosenMeditation.category ? MEDITATION_EXAMPLE[chosenMeditation.category] : "What's on your mind today?"; 

    return <div className="w-full flex flex-col space-y-8">
        <div className="w-full text-center text-5xl font-garamond">
            {question} 
        </div>
        <div className="relative">
            <textarea 
                autoFocus
                className="w-full h-48 bg-white/40 border border-black rounded-xl p-4 resize-none focus:outline-none text-lg"
                value={inputText} 
                onChange={(e) => {setInputText(e.target.value)}}
                placeholder ={example}
                />
            <div className="absolute right-0 bottom-2">
                <WordCounter wordCount={inputText.split(' ').length}/>
            </div>
        </div>
        <div className="text-center">
            <button className="text-center align-center text-lg text-black font-garamond mx-0" onClick={() => setShowExplain(!showExplain)}>
            How will this be used?
        </button>
        {showExplain && <div className="text-center text-md font-garamond my-2">
            {explain} 
        </div>}
        </div>
        
        
        <div className="w-full flex flex-row space-x-12 justify-center">
            <button className="px-8 py-2 cursor-pointer underline decoration-transparent hover:decoration-black transition-all duration-500" 
            onClick={() => {setTimeout(onDone, 400)
                        }
                }>
                Skip
            </button>
            <PrimaryButton onClick={onDone} disabled={inputText.split(" ").length < 15}>
                Continue
            </PrimaryButton>
        </div>
    </div>
}

const WordCounter = (
    {
        wordCount
    } : {
        wordCount: number
    }
) => {

    const getText = () => {
        if (wordCount >= 15) return "The more the better!"
        else if (wordCount >= 9) return "Just a bit more!"
        else if (wordCount >= 5) return "Keep writing!"
        else return "Great start!"
    }
    
    return <div className={`flex flex-row items-center space-x-2 text-xs italic transition-all duration-500 rounded-xl m-2 px-2 py-1 ${wordCount < 2 ? "opacity-0" : "opacity-100"}`}>
        <div className="">
            { getText()}
        </div>
        <div className="flex flex-row space-x-[1.5px] ">
            <div className={`w-[10px] h-[7px] rounded-l-md rounded-r-sm bg-neutral-300 overflow-hidden`}>
                <div className={`h-[8px] bg-neutral-500 transition-all duration-1000 ${wordCount < 5 ? "w-[0px]" : "w-[10px]"}`} />
            </div>
            <div className={`w-[10px] h-[7px] rounded-sm bg-neutral-300 overflow-hidden`}>
                <div className={`h-[8px] bg-neutral-500 transition-all duration-1000 ${wordCount < 9 ? "w-[0px]" : "w-[10px]"}`} />
            </div>
            <div className={`w-[10px] h-[7px] rounded-r-md rounded-l-sm bg-neutral-300 overflow-hidden`}>
                <div className={`h-[8px] bg-neutral-500 transition-all duration-1000 ${wordCount < 15 ? "w-[0px]" : "w-[10px]"}`} />
            </div>
        </div>
        
    </div>
}