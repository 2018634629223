import { useState } from "react";

const LandingFAQ = () => {


    return <section className="py-5 sm:py-10 font-garamond text-lg">
        <div className="max-w-2xl mx-auto text-center w-full">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl mb-12">FAQ</h2>
            <p className="max-w-xl text-lg mx-auto mt-4 text-base font-raleway text-black mb-12">For any inquires please contact us at contact@sitguide.ai</p>
        </div>
        <div className="w-full text-center flex flex-col space-y-8">
            {getAllFaq().map((faq, index) => <FAQCard key={index} faq={faq} />)}
        </div>

    </section>
}

export type FAQ = {
    question: string,
    answer: string
}

const getAllFaq = (): FAQ[] => {
    let faqs: FAQ[] = [];

    faqs.push({
        question: "What unique features does Sit offer?",
        answer: "As a personalized meditation app, Sit offers a range of unique features tailored to individual users. The app provides audio-guided meditations that are customizable to your specific needs and preferences, including different types of meditations, session lengths, and settings. Over time, the app learns from your behavior, feedback, and preferences, allowing it to proactively recommend personalized meditation exercises and self-help activities."
    });
    faqs.push({
        question: "How does Sit personalize my meditation experience?",
        answer: "Sit harnesses the power of AI to create a personalized meditation experience. We combine our keen understanding of powerful and commonly practiced meditation techniques (e.g. Loving-Kindness, Gratitude, Body-Scan etc) and design them to meet you at the right moment of need with the use of AI. Sit uses OpenAI’s algorithms for guided meditation design and Eleven Labs text-to-speech technology. The more you use the app, the more accurate becomes in understanding and catering to your meditation needs."
    });
    faqs.push({
        question: "Is Sit free?",
        answer: "You have 2 free credits everyday for 30 days to generate 2 custom meditations per day."
    });
    faqs.push({
        question: "Is Sit suitable for beginners who are new to meditation?",
        answer: "Absolutely! Sit is designed for everyone, whether you are new to meditation or have been practicing for years. The app allows beginners to customize the length and type of their meditation sessions, making it easier to get started. As you become more experienced, the app will adapts and starts recommending more advanced sessions, ensuring continuous growth and progression in your meditation journey."
    });
    faqs.push({
        question: "What data does Sit collect?",
        answer: "Privacy is an utmost priority and we handle your data with utmost care, adhering to stringent data protection and privacy standards. All interactions within the app are confidential and secure. We only send anonymised data to OpenAI and Eleven Labs models for generating and fine tuning prompts on a user’s journal entry. According to Open AI’s data policy as of March 1st 2023, OpenAI will not use data submitted by customers via our API to train OpenAI models or improve OpenAI’s service offering. You can read more about their policy here."
    });
    faqs.push({
        question: "Do you have a mobile app?",
        answer: "Not yet! Our experience is mobile-friendly using any web browser. Stay tuned for an announcement for our mobile app."
    });
    faqs.push({
        question: "Who built Sit?",
        answer: "Sit is the brainchild of a team of loving engineers, mindfulness facilitators & creatives. They are excited to see progressive uses of AI and have previously worked at Google, Uber, IDEO & South Park Commons etc."
    });

    return faqs;
}

export const FAQCard = ({ faq }: { faq: FAQ }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        console.log("opened")
        setExpanded(!expanded);
    }

    return (
        <div className="max-w-[600px] mx-auto mt-8 md:mt-8 font-raleway text-left">
            <div className={`transition-all duration-200 pb-2 border-b border-black cursor-pointer`}
                onClick={toggleExpanded}>
                
                <div className="flex items-center justify-between w-full">

                    <span className="flex text-lg font-semibold text-black">{faq.question}</span>

                    <svg className={`transition-all duration-500 w-6 h-6 text-black ${expanded ? 'rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </div>
                    <div className={`transition-all duration-500 overflow-hidden ${expanded ? "max-h-[300px]" : "max-h-[0px]"}`}>
                        <div className={`text-left mb-12`}>
                            {faq.answer}
                        </div>
                    </div>
                    
                
            </div>
        </div>
    );
}

export default LandingFAQ;