import { useState } from 'react';
import { GoogleAuthProvider, createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebase';
import { createUser } from '../lib/createUser';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../components/UI';

const Signup = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const onSubmit = async () => {

        await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                createUser(user.uid)
                navigate('/whatsyourname');
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
    }

    const signUpWithGoogle = () => {
        const googleAuthProvider = new GoogleAuthProvider();
        googleAuthProvider.setCustomParameters({
            prompt: 'select_account',
        });
        signInWithPopup(auth, googleAuthProvider).then((result) => {
            navigate("/whatsyourname");
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <div className="container mx-auto flex flex-col items-center min-h-[100vh]">
            
            <div className="w-[300px]">
                <div className="text-4xl font-garamond mb-4 mt-48 text-center w-full">Sign Up </div>    
                <form className="flex flex-col space-y-4 p-4 rounded-lg bg-white shadow-lg" >
                    <div className="w-full">
                        <label htmlFor="email-address">Email address</label>
                        <input
                            className="border border-gray-400 w-full p-2 rounded-md text-black"
                            id="email-address"
                            name="email"
                            type="email"
                            required
                            placeholder="Email address"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="w-full">
                        <label htmlFor="password">Password</label>
                        <input
                            className="border border-gray-400 w-full p-2 rounded-md text-black"
                            id="password"
                            name="password"
                            type="password"
                            required
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <PrimaryButton onClick={() => {onSubmit()}}>Sign up</PrimaryButton>
                    <div className="w-full flex flex-row items-center justify-center space-x-2 text-xs text-neutral-500 px-4">
                        <div className="w-full h-[1px] rounded-full bg-neutral-500" />
                        <div>
                            or
                        </div>
                        <div className="w-full h-[1px] bg-neutral-500" />
                    </div>
                    <PrimaryButton onClick={signUpWithGoogle}>Sign up with Google</PrimaryButton>
                    
                    <div className="text-sm w-full text-center">
                    Already have an account?{' '}
                        <span
                            className="underline cursor-pointer text-center"
                            onClick={() => { navigate('/login') }}>
                            
                            Login
                        </span>
                    </div>
                </form>


            </div>
        </div>
    )
}

export { Signup }