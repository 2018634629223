import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../App";
import { MeditationConfig } from "./MeditationConfig";
import { CategoryPicker } from "./CategoryPicker";
import { TextInput } from "./TextInput";
import { BackArrow } from "../icons";
import { DurationPicker } from "./DurationPicker";
import { VoicePicker } from "./VoicePicker";
import { PrimaryButton } from "../UI";
import { LoadingMeditation } from "./LoadingMeditation";
import { ThemePicker } from "./ThemePicker";

export const DEFAULT_CONFIG = {
    uid: undefined,
    category: undefined,
    duration: undefined,
    inputText: undefined,
    voice: undefined,
    theme: null
}

export const MeditationDesigner = () => {
    const { currentUser } = useContext(AuthContext);
    const [meditationConfig, setMeditationConfig] = useState<MeditationConfig>({
        ...DEFAULT_CONFIG,
        uid: currentUser.uid,
        username: currentUser.displayName
    })
    const [step, setStep] = useState<number>(0);

    const getStep = () => {
        switch (step) {
            case 0: 
                return <CategoryPicker 
                    config={meditationConfig} 
                    setConfig={setMeditationConfig}
                    onDone={() => {setStep(1)}}/>;
            case 1: 
                return <TextInput
                    inputText={meditationConfig.inputText ? meditationConfig.inputText : ""}
                    chosenMeditation={meditationConfig}
                    setInputText={(inputText) => {
                        setMeditationConfig({
                            ...meditationConfig,
                            inputText: inputText
                        })
                    }}
                    
                    onDone={() => {setStep(2)}}
                    />
                          
            case 2: return <div className="flex flex-col space-y-12 pb-24">
                    <ThemePicker
                        onDone={() => {setStep(3)}}
                        theme={meditationConfig.theme}
                        setTheme={(theme) => {
                            setMeditationConfig({
                                ...meditationConfig,
                                theme: theme
                            })
                        }}/>
                </div>

            case 3: return <div className="flex flex-col space-y-12">
                    <DurationPicker
                        duration={meditationConfig.duration ? meditationConfig.duration : 0} //default selection
                        setDuration={(duration) => {
                            setMeditationConfig({
                                ...meditationConfig,
                                duration: duration
                            })
                        }}/>
                    <VoicePicker
                        voice={meditationConfig.voice}
                        onDone={() => {}}
                        setVoice={(voice) => {
                            setMeditationConfig({
                                ...meditationConfig,
                                voice: voice
                            })      
                        }}/> 
                    <PrimaryButton onClick={() => {setStep(4)}}
                        disabled={meditationConfig.duration === undefined || meditationConfig.voice === undefined}>
                        <div className="py-3">Generate Custom Meditation</div>
                    </PrimaryButton>
                    </div>
            case 4:
                return <LoadingMeditation meditationConfig={meditationConfig}/>
        }
    }

    const showBackButton = (step > 0 && step < 4)

    return <div className="w-full flex flex-col max-w-[600px] mt-24 relative min-h-[100vh]">
        <div className="absolute right-[110%] top-0 fill-neutral-800">
            <div className={`p-2 border border-transparent rounded-full hover:border-black cursor-pointer transition-all duration-500
                ${showBackButton ? "opacity-100" : "opacity-0"}`}
                onClick={() => {setStep(step => step - 1)}}>
                {BackArrow}
            </div>
        </div>
        {
            getStep()
        }
        
    </div>
}

