import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from 'three';
import vertex from "./shaders/vertex";
import fragment from "./shaders/fragment";
import { GradientParams } from "./ScrollingBackgroundGradient";

export class SimpleSketch {
    scene: THREE.Scene;
    container: HTMLElement;
    renderer: THREE.WebGLRenderer;
    width: number;
    height: number;
    camera: THREE.PerspectiveCamera;
    //controls: OrbitControls;
    time: number;
    nextTime: number;
    isPlaying: boolean;
    material: THREE.ShaderMaterial;
    geometry: THREE.PlaneGeometry;
    plane: THREE.Mesh;
    dracoLoader: DRACOLoader;
    gltf: GLTFLoader;
    palette: THREE.Color[];
    colorFreq: number[];
    animationSpeed: number;
    cutoff: number;

    constructor(dom: HTMLElement, params: GradientParams, palette: THREE.Color[], baseColor: THREE.Color ) {
        this.scene = new THREE.Scene();
        this.container = dom;
        this.width = this.container.offsetWidth;
        this.height = this.container.offsetHeight;
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.renderer.setSize(this.width, this.height);
        this.renderer.setClearColor(0xFFFFFF, 1);
        this.animationSpeed = 0.000009;
        //this.renderer.physicallyCorrectLights = true;
        //this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.container.appendChild(this.renderer.domElement);
        
        this.camera = new THREE.PerspectiveCamera(70, this.width / this.height, 0.001, 100);

        //this.camera.position.set(0, 0, 0.5);
        this.camera.position.set(0, -0.1, 0.7);
        //this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.time = params.time ? params.time : 0;
        this.nextTime = params.time + 0.000004;
        
        this.dracoLoader = new DRACOLoader();
        this.dracoLoader.setDecoderPath('https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/js/libs/draco/');

        this.gltf = new GLTFLoader();
        this.gltf.setDRACOLoader(this.dracoLoader);

        this.isPlaying = true;
        this.palette = palette;

        this.colorFreq= params.colorFreq
        this.cutoff = 0.0;
        
        this.material = new THREE.ShaderMaterial({
            extensions: {},
            side: THREE.DoubleSide,
            uniforms: {
                time: { value: this.time },
                nextTime: {value: this.nextTime},
                resolution: { value: new THREE.Vector4 },
                uColor: { value: this.palette},
                colorFreq: {value: this.colorFreq},
                cutoff: {value: this.cutoff},
                baseColor: {value: baseColor}
            },
            wireframeLinewidth: 20,
            wireframe: false,
            vertexShader: vertex,
            fragmentShader: fragment
        });

        this.geometry = new THREE.PlaneGeometry(2, 1, 300, 300);
        this.plane = new THREE.Mesh(this.geometry, this.material);
        this.plane.rotateX(Math.PI * -0.15);
        this.plane.translateZ(0.25);
        this.scene.add(this.plane);

        const light1 = new THREE.AmbientLight(0xffffff, 1);
        this.scene.add(light1);
        const light2 = new THREE.DirectionalLight(0xffffff, 1);
        light2.position.set(0.5, 0, 0.866);
        this.scene.add(light2);

        this.resize();
        this.render();
        this.setupResize();
    }

    setCutoff(c: number) {
        this.cutoff = c;
        this.time += this.animationSpeed * 2;
    }

    setupResize() {
        window.addEventListener('resize', this.resize.bind(this));
    }

    stop() {
        //console.log("stopping", this.time, (this.nextTime - this.time) / this.interval);
        this.isPlaying = false;
    }

    play() {
        //console.log(this.time)
        if (!this.isPlaying) {
            //console.log("playing", this.time, (this.nextTime - this.time) / this.interval);
            this.isPlaying = true;
            this.render();
        }
    }

    resize() {
        this.width = this.container.offsetWidth;
        this.height = this.container.offsetHeight;
        this.renderer.setSize(this.width, this.height);
        this.camera.aspect = this.width / this.height;

        this.material.uniforms.resolution.value.x = this.width;
        this.material.uniforms.resolution.value.y = this.height;

        this.camera.updateProjectionMatrix();
        
    }

    render() {
        //console.log("render", (this.nextTime - this.time) / this.interval);

        if (!this.isPlaying) return;

        this.time += (this.cutoff < 0.3) ? this.animationSpeed * 4 : this.animationSpeed;
        
        this.material.uniforms.time.value = this.time;
        this.material.uniforms.cutoff.value = this.cutoff;
        this.renderer.render(this.scene, this.camera);

        setTimeout(() => {
            requestAnimationFrame(this.render.bind(this));
          }, 1000 / 30);
        //window.requestAnimationFrame(this.render.bind(this));

    }


}