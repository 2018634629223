import { useNavigate } from "react-router";
import { Meditation } from "./Meditation"
import { SmallPlayCircle } from "./icons";

export const PreviousMeditation = (
    {
        meditation
    } : {
        meditation: Meditation
    }
) => {

    const navigate = useNavigate();
    
    return <div className="w-full sm:w-1/3">
        <div className="mr-4 mb-4 p-4 flex flex-col space-y-2  border border-black rounded-xl cursor-pointer hover:bg-black/20 transition-all duration-500 "
            onClick={() => {navigate("/meditation/" + meditation.docId)}}>

            <div className="text-xs font-bold">{meditation.config.category}</div>

            <div className="text-2xl leading-7">{meditation.title?.split(":")[0]}</div>

            <div className="text-md leading-5">{meditation.dateCreated ? getDateTag(meditation.dateCreated) : ""}</div>

            <div className="flex flex-row items-center space-x-1">
                <div>{SmallPlayCircle}</div>
                <div>{meditation.config.duration + " min"} </div>
            </div>

        </div>
        
        
    </div>
}

export const getDateTag = (date: Date)=> {
    if (date.getDate() === new Date().getDate()) {
        return "Today"
    }
    if (date.getDate() === new Date().getDate() - 1) {
        return "Yesterday"
    }
    const weekday = date.toLocaleString('en-us', {  weekday: 'long' });
    const month = date.toLocaleString('en-us', {  month: 'short' });
    return `${weekday}, ${month} ${date.getDate()}`

}