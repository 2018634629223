import { useRef, useEffect, useState } from "react";
import { BackArrow2, ForwardArrow, PauseCircle, PlayCircle } from "../icons";
import { motion, useInView } from "framer-motion";
import demo1 from "../../audio/demo1.mp3";
import demo2 from "../../audio/demo2.mp3";
import demo3 from "../../audio/demo3.mp3";

export const LandingDemo = () => {
    const [slideIndex, setSlideIndex] = useState<number>(0);
    const slideRef = useRef(null);

    useEffect(() => {
        if (slideRef && slideRef.current) {
            const translate = -1 * slideIndex / 3 * 100;
            (slideRef.current as any).style.transform = `translateX(${translate}%)`;
        }
    }, [slideIndex])

    return <div className="relative">
        <div className="absolute right-[100%] top-[40%]">
            {slideIndex > 0 && 
                <div className="flex flex-col items-center justify-center cursor-pointer rounded-full hover:bg-black/20 p-3 pl-4 transition-all duration-500 "
                    onClick={() => {
                        setSlideIndex(slideIndex => Math.max(0, slideIndex - 1));
            }}>
                {BackArrow2}
            </div>}
        </div> 
        <div className="absolute left-[100%] top-[40%]">
            {slideIndex < 2 && 
                <div className="flex flex-row items-center justify-center cursor-pointer rounded-full hover:bg-black/20 p-3 transition-all duration-500 "
                    onClick={() => {
                setSlideIndex(slideIndex => Math.min(2, slideIndex + 1));
            }}>
                {ForwardArrow}
            </div> }
        </div>
        <div className="relative w-full sm:max-w-[700px] overflow-x-hidden">
            
            <div ref={slideRef} className="sm:w-[300%] flex flex-col space-y-24 sm:space-y-0 sm:flex-row sm:items-start sm:justify-around transition-all duration-[700ms] my-10">
                <DemoComponent
                    key={0}
                    text="I have a big presentation tomorrow, my mind is racing and I can’t sleep"
                    themeOptions={["Space", "Rainforest", "Home", "Waterfall"]}
                    goalOptions={["relax", "focus", "motivation", "sleep"]}
                    title="Tranquil Journey: A Majestic Waterfall Meditation for David"
                    goal="Help me Sleep"
                    durationOptions={["3 min", "5 min", "7 min", "10 min"]}
                    meditationQuote="... know that you are more than capable, David. You have prepared diligently, honed your skills, and gathered the necessary knowledge..."
                    audioSource={demo1}
                    />

                <DemoComponent
                    key={2}
                    text="I just feel low energy - I want to go outside, take a walk and enjoy the breeze"
                    themeOptions={["Brooklyn", "Central Park", "Soho NYC"]}
                    goalOptions={["sleep", "relax", "gratitude", "mindful walking"]}
                    title="Shake It Off, Naomi - A Mindful Walk in Soho"
                    goal="Take a Relaxing Stroll"
                    durationOptions={["3 min", "5 min", "7 min"]}
                    meditationQuote="Naomi as you continue to walk mindfully through the vibrant streets of Soho, notice the kaleidoscope of colors, the unique architecture, and the diverse faces passing by..."
                    audioSource={demo2}
                    />

                <DemoComponent
                    key={1}
                    text="Just had a challenging conversation with my partner - I feel drained and upset"
                    themeOptions={["Garden", "Cityscape", "Space", "Ocean"]}
                    goalOptions={["soothe", "sleep", "anxiety", "compassion"]}
                    title="Sarah's Ocean of Love: Nurturing Compassion & Connection"
                    goal="Navigate Relationships"
                    durationOptions={["3 min", "5 min"]}
                    meditationQuote="Visualize yourself stepping into this boat, letting it carry you to the horizon. As you glide across the waters, imagine sending waves of love and compassion to yourself and your partner..."
                    audioSource={demo3}
                    />

            </div>
        </div>
    </div>
};

const DemoComponent = (
    { 
        text: initialText, 
        themeOptions, 
        goalOptions, 
        goal, 
        title, 
        durationOptions, 
        meditationQuote,
        audioSource,
    }: { 
        text: string, 
        themeOptions: string[], 
        durationOptions: string[],  
        goalOptions : string [], 
        goal: string, 
        title: string, 
        meditationQuote: string,
        audioSource: string
}) => {
    const [animationStep, setAnimationStep] = useState(-1);
    const {text, finished } = useTypingText(initialText, 60, animationStep >= 0);
    const duration = durationOptions[durationOptions.length-1]
    const viewRef = useRef(null);
    const isInView = useInView(viewRef);

    const [isPlaying, setIsPlaying] = useState(false);
    const [audio, setAudio] = useState(new Audio(audioSource));
    
    useEffect(() => {
        if (isPlaying === true) {
            audio.play();
        } else {
            audio.pause();
        }
    }, [isPlaying])

    useEffect(() => {
        if (isInView) {
            setAnimationStep(0);
        } else {
            setAnimationStep(-1);
            setIsPlaying(false);
        }
    }, [isInView])

    useEffect(() => {
        if (finished) setAnimationStep(1);

    }, [finished])

    useEffect(() => {
        switch (animationStep) {
            case -1: return;
            case 0: return;
            case 1: setTimeout(() => { setAnimationStep(2) }, 1500); return;
            case 2: setTimeout(() => { setAnimationStep(3) }, 1000); return;
        }
    }, [animationStep])

    return (
        <div className="w-full sm:max-w-[600px] p-8 bg-white/30 shadow-lg rounded-2xl flex flex-col space-y-4 ">
            <div className="font-bold w-full text-center">
            What is top of mind today?
           </div>
            <div className="w-full border border-black p-2 rounded-md h-[110px] sm:h-[70px]">
                {text}<span className="blinking-cursor">|</span>
            </div>
            <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row justify-between">
                <SettingChip
                    key={0}
                    label="Goal"
                    options={goalOptions}
                    delay={0}
                    start={animationStep >= 1} />
                
                <SettingChip 
                    key={1}
                    label="Setting" 
                    options={themeOptions}
                    delay={500}
                    start={animationStep >= 1}/>
                
                <SettingChip 
                    key={2}
                    label="Duration" 
                    options={durationOptions}
                    delay={1000}
                    start={animationStep >= 1}/>
            </div>
            <div className="w-full flex flex-row justify-end">
                <div className="w-2" ref={viewRef} />
            </div>
            <div className="flex flex-row justify-center items-center sm:h-56">
                
                {(animationStep <= 2) && <Generating show={animationStep === 2}/> }

                {(animationStep === 3) && 
                    <MeditationDemo 
                        title={title} 
                        goal={goal} 
                        duration={duration} 
                        meditationQuote={meditationQuote}
                        isPlaying={isPlaying}
                        setIsPlaying={setIsPlaying} /> }
            </div>
        </div>
    );
}

export const Generating = (
    {
        show
    } : {
        show: boolean
    }
) => {

    return <div className="flex flex-row items-center justify-center h-36">
        <div className={`${show ? "opacity-100" : "opacity-0"} loader`}/>
    </div>
}

export const MeditationDemo = (
    {
        goal,
        title,
        duration,
        meditationQuote,
        isPlaying,
        setIsPlaying
    } : { 
        goal: string,
        title: string,
        duration: string,
        meditationQuote:string,
        isPlaying: boolean,
        setIsPlaying: (isPlaying: boolean) => void
}) => {
   
    return <motion.div initial={{opacity: 0, scale: 0.8}} animate={{opacity: 1, scale: 1}} transition={{duration: 1, ease: [.16,1.38,.77,1.21]}} className="h-auto flex flex-col items-center py-4">
            <div className="rounded-xl border border-black flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 space-x-3 p-4 cursor-pointer hover:bg-black/70 transition-all duration-1000 bg-black text-white">
                <div className="fill-white"
                    onClick={() => {setIsPlaying(!isPlaying)}}>
                    { isPlaying ? PauseCircle : PlayCircle}
                </div>
                <div className="flex flex-col sm:text-left text-center">
                    <div className="text-xs font-bold">
                        {goal}
                    </div>
                    <div className="text-2xl">
                        {title}
                    </div>
                    <div>
                        {duration}
                    </div>
                </div>      
            </div>
        <div className="text-sm italic my-4 items-center text-center">{meditationQuote}</div>
    </motion.div>
 
}

export const SettingChip = (
    {
        label,
        options,
        delay,
        start
    } : {
        label: string,
        options: string[],
        delay: number,
        start: boolean
}) => {
    const ref = useRef(null);
    useEffect(() => {
        if (start) {
            const t = setTimeout(() => {
                if (ref && ref.current) {
                    (ref.current as any).style.transform = `translateY(-${(options.length - 1) * 25}px)`;
                }
            }, delay)
            
            return () => {
                clearTimeout(t)
            }
        }
        else {
            if (ref && ref.current) {
                (ref.current as any).style.transform = `translateY(0px)`;
            }
        }
    }, [start])

    return <div className="w-full sm:w-1/3 flex flex-row space-x-2 items-center justify-between text-sm">
        <div className="font-bold">
            {label}:
        </div>
        <div className="w-full h-[30px] border rounded-md px-2 py-1 overflow-hidden hitespace-nowrap">
            <div ref={ref} className={`flex flex-col transition-all duration-1000`}>
                {options.map((option, index) => <div key={index} className="h-[25px]">{option}</div>)}
            </div>
        </div>
</div>
}

const useTypingText = (text: string, delay: number, animate: boolean) => {
    const [typingText, setTypingText] = useState<string>(text.substring(0, 50));
    const [typingIndex, setTypingIndex] = useState<number>(50);

    useEffect(() => {
        if (animate === false) {
            setTypingIndex(50)
        }
    }, [animate])

    useEffect(() => {
        if (!animate) return;
        
        if (typingIndex > text.length + 1) return;

        const interval = setInterval(() => {
            setTypingText(text.substring(0, typingIndex));
            setTypingIndex(typingIndex + 2);
        }, delay);

        return () => clearInterval(interval);
    }, [typingIndex, animate]);

    return {text: typingText, finished: typingIndex >= text.length};
}