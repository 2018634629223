import { PrimaryButton } from "../UI"

export const LandingHero2 = () => {


    return <div className="w-full flex flex-row items-start min-h-[70vh]">
        <div className="w-full flex flex-col items-start space-y-8">
            <div className="w-full font-raleway font-light text-5xl decoration-2 leading-snug">
                Let us take you <br/>
                on a <span className="underline">personalized</span> meditation <br/>
                tailored for <span className="underline">you.</span>
            </div>
            <div className="w-full font-raleway text-xl italic">
                Our meditations are designed for your needs and your time.
            </div>
            
            <PrimaryButton onClick={() => {}}>
                <a href="https://tazs7mxpndu.typeform.com/to/cxJTFPuZ">GET EARLY ACCESS</a>
            </PrimaryButton>
        </div>
    </div>
}