export const BackArrow = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-175.386 175.386-480 480-784.614l42.768 42.153-232.462 232.462h494.308v59.998H290.306l232.462 232.462L480-175.386Z"/></svg>
export const PlayCircle = <svg xmlns="http://www.w3.org/2000/svg" height="80" viewBox="0 -960 960 960" width="80"><path d="M401.461-341.538 618.462-480 401.461-618.462v276.924ZM480.134-120q-74.442 0-139.794-28.339-65.353-28.34-114.481-77.422-49.127-49.082-77.493-114.373Q120-405.425 120-479.866q0-74.673 28.339-140.41 28.34-65.737 77.422-114.365 49.082-48.627 114.373-76.993Q405.425-840 479.866-840q74.673 0 140.41 28.339 65.737 28.34 114.365 76.922 48.627 48.582 76.993 114.257Q840-554.806 840-480.134q0 74.442-28.339 139.794-28.34 65.353-76.922 114.481-48.582 49.127-114.257 77.493Q554.806-120 480.134-120ZM480-150.769q137.385 0 233.308-96.039Q809.231-342.846 809.231-480q0-137.385-95.923-233.308T480-809.231q-137.154 0-233.192 95.923Q150.769-617.385 150.769-480q0 137.154 96.039 233.192Q342.846-150.769 480-150.769ZM480-480Z"/></svg>
export const PauseCircle = <svg xmlns="http://www.w3.org/2000/svg" height="80" viewBox="0 -960 960 960" width="80"><path d="M396.923-340h30.769v-280h-30.769v280Zm135.385 0h30.769v-280h-30.769v280Zm-52.174 220q-74.442 0-139.794-28.339-65.353-28.34-114.481-77.422-49.127-49.082-77.493-114.373Q120-405.425 120-479.866q0-74.673 28.339-140.41 28.34-65.737 77.422-114.365 49.082-48.627 114.373-76.993Q405.425-840 479.866-840q74.673 0 140.41 28.339 65.737 28.34 114.365 76.922 48.627 48.582 76.993 114.257Q840-554.806 840-480.134q0 74.442-28.339 139.794-28.34 65.353-76.922 114.481-48.582 49.127-114.257 77.493Q554.806-120 480.134-120ZM480-150.769q137.385 0 233.308-96.039Q809.231-342.846 809.231-480q0-137.385-95.923-233.308T480-809.231q-137.154 0-233.192 95.923Q150.769-617.385 150.769-480q0 137.154 96.039 233.192Q342.846-150.769 480-150.769ZM480-480Z"/></svg>
export const SmallPlayCircle = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M404-348.923 611.077-480 404-611.077v262.154ZM480.409-136q-70.948 0-133.685-26.839-62.737-26.84-109.865-73.922-47.127-47.082-73.993-109.716Q136-409.111 136-480.325q0-71.214 26.839-133.451 26.84-62.237 73.922-109.365 47.082-47.127 109.716-73.993Q409.111-824 480.325-824q71.214 0 133.451 26.839 62.237 26.84 109.365 73.922 47.127 47.082 73.993 109.482Q824-551.357 824-480.409q0 70.948-26.839 133.685-26.84 62.737-73.922 109.865-47.082 47.127-109.482 73.993Q551.357-136 480.409-136ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
export const MusicNote = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M400-120q-66 0-113-47t-47-113q0-66 47-113t113-47q23 0 42.5 5.5T480-418v-422h240v160H560v400q0 66-47 113t-113 47Z"/></svg>
export const AccountCircle = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M240.924-268.307q51-37.846 111.115-59.769Q412.154-349.999 480-349.999t127.961 21.923q60.115 21.923 111.115 59.769 37.308-41 59.116-94.923Q800-417.154 800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 62.846 21.808 116.77 21.808 53.923 59.116 94.923Zm239.088-181.694q-54.781 0-92.396-37.603-37.615-37.604-37.615-92.384 0-54.781 37.603-92.396 37.604-37.615 92.384-37.615 54.781 0 92.396 37.603 37.615 37.604 37.615 92.384 0 54.781-37.603 92.396-37.604 37.615-92.384 37.615Zm-.012 350q-79.154 0-148.499-29.77-69.346-29.769-120.654-81.076-51.307-51.308-81.076-120.654-29.77-69.345-29.77-148.499t29.77-148.499q29.769-69.346 81.076-120.654 51.308-51.307 120.654-81.076 69.345-29.77 148.499-29.77t148.499 29.77q69.346 29.769 120.654 81.076 51.307 51.308 81.076 120.654 29.77 69.345 29.77 148.499t-29.77 148.499q-29.769 69.346-81.076 120.654-51.308 51.307-120.654 81.076-69.345 29.77-148.499 29.77ZM480-160q54.154 0 104.423-17.423 50.27-17.423 89.27-48.731-39-30.154-88.116-47Q536.462-290.001 480-290.001q-56.462 0-105.77 16.654-49.308 16.654-87.923 47.193 39 31.308 89.27 48.731Q425.846-160 480-160Zm0-349.999q29.846 0 49.924-20.077 20.077-20.078 20.077-49.924t-20.077-49.924Q509.846-650.001 480-650.001t-49.924 20.077Q409.999-609.846 409.999-580t20.077 49.924q20.078 20.077 49.924 20.077ZM480-580Zm0 355Z"/></svg>
export const ForwardArrow = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m304-82-56-57 343-343-343-343 56-57 400 400L304-82Z"/></svg>
export const BackArrow2 = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M400-80 0-480l400-400 56 57-343 343 343 343-56 57Z"/></svg>
export const ThreeDots = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M249.231-420.001q-24.749 0-42.374-17.625-17.624-17.625-17.624-42.374 0-24.749 17.624-42.374 17.625-17.625 42.374-17.625 24.75 0 42.374 17.625Q309.23-504.749 309.23-480q0 24.749-17.625 42.374-17.624 17.625-42.374 17.625Zm230.769 0q-24.749 0-42.374-17.625-17.625-17.625-17.625-42.374 0-24.749 17.625-42.374 17.625-17.625 42.374-17.625 24.749 0 42.374 17.625 17.625 17.625 17.625 42.374 0 24.749-17.625 42.374-17.625 17.625-42.374 17.625Zm230.769 0q-24.75 0-42.374-17.625Q650.77-455.251 650.77-480q0-24.749 17.625-42.374 17.624-17.625 42.374-17.625 24.749 0 42.374 17.625 17.624 17.625 17.624 42.374 0 24.749-17.624 42.374-17.625 17.625-42.374 17.625Z"/></svg>
export const PlayButtonSmall = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m380-300 280-180-280-180v360ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
export const EastArrow = <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="m578.462-225.384-24.103-24.103 213.59-213.59H111.538v-33.846h656.411l-213.59-213.59 24.103-24.103L833.077-480 578.462-225.384Z"/></svg>
export const WestArrow = <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M366.154-225.384 111.538-480l254.616-254.616 24.179 24.103-214.102 213.59h656.846v33.846H176.231l214.025 213.59-24.102 24.103Z"/></svg>
export const FilledPlayButton = <svg xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 -960 960 960" ><path d="M320-203v-560l440 280-440 280Z"/></svg>
export const FilledPauseButton = <svg xmlns="http://www.w3.org/2000/svg" height="100" viewBox="0 -960 960 960" width="100"><path d="M555-200v-560h175v560H555Zm-325 0v-560h175v560H230Z"/></svg>