import { useContext, useEffect, useState } from "react";
import { VideoScriptGenerator } from "./VideoScriptGenerator";
import { VideoOptions } from "./VideoOptions";
import { TRACK } from "../MeditationMusic";
import { VideoPlayer } from "./VideoPlayer";
import { SimpleBackgroundGradient } from "../Gradient/SimpleBackgroundGradient";
import { LoadingMeditation } from "../MeditationDesigner/LoadingMeditation";
import { MeditationCategory, MeditationConfig, VoiceOption } from "../MeditationDesigner/MeditationConfig";
import { PrimaryButton } from "../UI";
import { scriptToMeditation } from "../Meditation";
import { createMeditation } from "../../lib/useMeditation";
import { useNavigate } from "react-router";
import { AuthContext } from "../../App";
import { db, functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { collection } from "firebase/firestore";


export const VideoCreator = () => {

    const [script, setScript] = useState("");
    const {currentUser} = useContext(AuthContext)
    
    const [meditationConfig, setMeditationConfig] = useState<MeditationConfig | null>(null);
    const [meditationId, setMeditationId] = useState<string | undefined>(undefined);
    const generatedRef = (currentUser.uid && meditationId) ? collection(db, "users", currentUser.uid, "meditations", meditationId, "generated") : null;
    const [segments, loading] = useCollectionData(generatedRef);

    const navigate = useNavigate();

    const loadMeditation = () => {
        console.log(meditationConfig, currentUser.uid, script, meditationConfig)

        if (meditationConfig && currentUser.uid && script) {
            let meditation = scriptToMeditation(currentUser.uid, script, meditationConfig, "title");
            //let meditation = scriptToMeditation(meditationConfig.uid, sampleScriptShort);
            console.log("creating meditation");

            createMeditation(currentUser.uid, meditation)
                .then((docRef) => {
                    console.log("Meditation created", meditation.segments)
                    setMeditationId(docRef.id);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    useEffect(() => {
        setMeditationConfig(
            {
                uid: currentUser.uid,
                username: "username",
                title: "video",
                inputText: "",
                category: MeditationCategory.GRATITUDE,
                duration: 5,
                voice: VoiceOption.Saguna,
                theme: null,
            } as MeditationConfig
        )
    }, [])
    
    return <div className="container mx-auto flex flex-col space-y-10">
        <label className="font-bold"> Voice</label>
        <select 
            value={meditationConfig?.voice}
            onChange={(e) => {
            setMeditationConfig({
                ...meditationConfig,
                voice: e.target.value as VoiceOption
            } as MeditationConfig)
        }}>
            <option value={VoiceOption.Lawrence}>Lawrence</option>
            <option value={VoiceOption.Saguna}>Ira</option>
        </select>
        <label className="font-bold"> Duration</label>
        <select 
            value={meditationConfig?.duration}
            onChange={(e) => {
            setMeditationConfig({
                ...meditationConfig,
                duration: parseInt(e.target.value)
            } as MeditationConfig)
            
        }}>
            <option value={1}>1</option>
            <option value={3}>3</option>
            <option value={5}>5</option>
            <option value={7}>7</option>
        </select>
        <label className="font-bold"> Script</label>
        <textarea
            className="w-full h-[70vh]"
            value={script}
            onChange={(e) => {setScript(e.target.value)}}></textarea>

        {JSON.stringify(meditationConfig)}
        
        <PrimaryButton 
            onClick={loadMeditation}
            disabled={!meditationConfig}
            dark>
            <div>Create Meditation DB</div>
        </PrimaryButton>

        {meditationId && <div>
                Meditation Created:
                {meditationId}
                <PrimaryButton onClick={() => {navigate('/videomeditation/' + meditationId)}}>
                    Go to Meditation
                </PrimaryButton>
                
            </div>} 
        
        
        

        
        <div className="h-36"></div>

    </div>     

}