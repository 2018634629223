import { useContext, useEffect, useState } from "react";
import deep from "../audio/deep.mp3";
import rain from "../audio/rain.mp3";
import relax from "../audio/relax.mp3";
import { MusicNote } from "./icons";
import { AuthContext } from "../App";

export enum TRACK {
    NONE = "none",
    DEEP = "deep",
    RAIN = "rain",
    RELAX = "relax"
}

export const TRACK_OPTIONS = [
    TRACK.NONE,
    TRACK.DEEP,
    TRACK.RAIN,
    TRACK.RELAX
]

export const TRACK_TO_SOURCE = {
    [TRACK.NONE]: undefined,
    [TRACK.DEEP]: deep,
    [TRACK.RAIN]: rain,
    [TRACK.RELAX]: relax,
}

export const MeditationMusic = (
    {
        isPlaying
    } : {
        isPlaying: boolean
}) => {

    const {preferences, updatePreferences} = useContext(AuthContext);
    
    const [expanded, setExpanded] = useState<boolean>(false);
    const [selectedTrack, setSelectedTrack] = useState<TRACK>(preferences ? preferences.music : TRACK.NONE);
    const [audio, setAudio] = useState<HTMLAudioElement | undefined>(undefined);
    const [volume, setVolume] = useState<number>(0.5);
    const [fadeOut, setFadeOut] = useState<boolean>(false);

    const selectTrack = (track: TRACK) => {
        if (selectedTrack === track) {
            setExpanded(expanded => !expanded);
        }
        else {
            setSelectedTrack(track);
            setExpanded(false);
        }
    }

    useEffect(() => {
        audio?.pause();
        const newAudio = new Audio(TRACK_TO_SOURCE[selectedTrack]);
        newAudio.volume = 0.5;
        setAudio(newAudio);
        updatePreferences("music", selectedTrack);
    }, [selectedTrack])

    useEffect(() => {
        if (isPlaying) {
            audio?.play()
                .catch((error) => {
                    console.log("play interrupted")
                });
            setFadeOut(false);
        } else {
            setFadeOut(true);
        }
    }, [isPlaying])

    useEffect(() => {
        if (isPlaying) {
            audio?.play()
                .catch((error) => {
                    console.log("play interrupted")
                });
        }
        return () => {
            audio?.pause();
        }
    }, [audio])

    useEffect(() => {
        if (audio) audio.volume = volume;

        if (fadeOut) {
            if (volume < 0.01) {
                setFadeOut(false);
                audio?.pause();
            } else {
                setTimeout(() => {
                    setVolume(volume => volume * .95)
                }, 30);
            }
        }
    }, [volume])

    useEffect(() => {
        if (fadeOut) {
            setVolume(0.49);
        } else {
            setVolume(0.5);
        }
    }, [fadeOut])

    return <div className={`relative transition-all duration-500 ${expanded ? "opacity-100" : "opacity-70"}`}>
        <div className=" p-3 rounded-3xl border border-black transition-all duration-500">
            {TRACK_OPTIONS.map((track, index) => 
                <TrackOption 
                    key={index}
                    track={track}
                    selected={selectedTrack === track}
                    expanded={selectedTrack === track || expanded}
                    onClick={() => {selectTrack(track)}}/> )}
        </div>
    </div>
}

const TrackOption = (
    {
        track,
        selected,
        onClick,
        expanded
    } : {
        track: TRACK,
        selected: boolean,
        onClick: () => void,
        expanded: boolean
}) => {

    return <div className={`transition-all duration-500 overflow-hidden ${expanded ? "max-h-[24px] opacity-100" : "max-h-[0px] opacity-0"}`}>
        <div className="flex flex-row items-center cursor-pointer hover:underline"
            onClick={onClick}>
            <div className={`fill-neutral-700 transition-all duration-500  ${selected ? "opacity-100" : "opacity-0"}`}>
                {MusicNote}
            </div>
            <div className="pr-2">
                {track}
            </div>
        </div>
    </div>

}