import { Chip } from "../UI";
import { MEDITATION_CATEGORY_OPTIONS, MeditationCategory, MeditationConfig } from "./MeditationConfig"

export const CategoryPicker = (
    {
        config,
        setConfig,
        onDone,
    } : {
        config: MeditationConfig,
        setConfig: (config: MeditationConfig) => void,
        onDone: () => void,
    }
) => {
    
    return <div className="w-full flex flex-col space-y-4 mb-24">
        <div className="w-full text-center text-5xl font-garamond mb-8">
            What is your goal today?
        </div>
        {
            MEDITATION_CATEGORY_OPTIONS.map((category) => 
                <Chip 
                    key={category}
                    selected={config.category === category}
                    onClick={() => {
                        setConfig({
                            ...config,
                            category: category})
                        setTimeout(onDone, 800);
                        }}
                    blinkBeforeClick
                >
                    <div className="font-bold">{category}</div>
                </Chip>
            )
        }
    </div>
}

const CategoryOption = (
    {
        category,
        selected,
        onClick,
    } : {
        category: MeditationCategory,
        selected: boolean,
        onClick: () => void
}) => {

    return <div className={`w-full rounded-xl p-2 border border-black text-center cursor-pointer hover:bg-black/20 transition-all duration-500
                            ${selected ? "bg-black/20" : ""}    `}
                onClick={onClick}>
        {category}
    </div>
}