import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { LandingPage } from './routes/LandingPage';
import { Home } from './routes/Home';
import { Login } from './routes/Login';
import { Signup } from './routes/Signup';
import { createContext, useCallback, useContext } from 'react';
import { Meditate } from './routes/Meditate';
import { Create } from './routes/Create';
import { WhatsYourName } from './routes/WhatsYourName';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from './firebase';
import { User } from 'firebase/auth';
import { Navbar } from './components/Navbar';
import { Account } from './routes/Account';
import { useDocumentData, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { doc, updateDoc } from 'firebase/firestore';
import { Prompter } from './components/Prompter/prompter';
import { SimpleBackgroundGradient } from './components/Gradient/SimpleBackgroundGradient';
import { VideoCreator } from './components/MarketingVideo/VideoCreator';
import { VideoPlayer } from './components/MarketingVideo/VideoPlayer';
import { BuyPremium } from './components/Billing/BuyPremium';
import { FunctionsTest } from './routes/FunctionsTest';

function App() {

    return <div className="text-black font-quicksand">
        
        <AuthProvider>
            <div>
                <Navbar />
            
                <Routes>
                    
                    <Route index element={<LandingPage />} />

                    <Route path="home" element={
                        <RequireAuth>
                            <Home />
                        </RequireAuth>} />

                    <Route
                        path="meditation/:entryId"
                        element={
                            <RequireAuth>
                                <Meditate />
                            </RequireAuth>}
                    />

                    <Route path="login" element={<Login />} />

                    <Route path="account" element={
                        <RequireAuth>
                            <Account />
                        </RequireAuth>} />

                    <Route path="create" element={
                        <RequireAuth>
                            <Create />
                        </RequireAuth>} />

                    <Route path="signup" element={<Signup />} />

                    <Route path="whatsyourname" element={
                        <RequireAuth>
                            <WhatsYourName />
                        </RequireAuth>} />

                    <Route path="prompter" element={<Prompter />} />
                    <Route path="video" element={<RequireAuth><VideoCreator /></RequireAuth>} />
                    <Route path="gradient" element={<SimpleBackgroundGradient />} />

                    <Route
                        path="videomeditation/:entryId"
                        element={
                            <RequireAuth>
                                <VideoPlayer />
                            </RequireAuth>}
                    />

                    <Route
                        path="premium"
                        element={
                            <RequireAuth>
                                <BuyPremium />
                            </RequireAuth>}
                    />

                    <Route
                        path="functionstest"
                        element={
                            <RequireAuth>
                                <FunctionsTest />
                            </RequireAuth>}
                    />
                </Routes>
            </div>
        </AuthProvider>
    </div>
}

interface AuthContextType {
    currentUser: any,
    preferences: any,
    updatePreferences: (key: string, value: any) => void,
    loading: boolean;
}

export const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider = (
    {
        children
    }: {
        children: JSX.Element
    }) => {

    const [user, userLoading, userError] = useAuthState(auth);
    const userDocRef = user ? doc(db, "users", user.uid) : null;
    const [userDoc, userDocLoading, preferencesError] = useDocumentData(userDocRef);

    const updatePreferences = useCallback((key: string, value: any) => {
        if (userDocRef && userDoc) {
            updateDoc(userDocRef, {
            preferences: {
                ...userDoc.preferences,
                [key]: value
            }})
        }
    }, [userDoc])
    
    const value: AuthContextType = {
        currentUser: user ? user : null,
        preferences: (userDoc && userDoc.preferences) ? userDoc.preferences : null,
        loading: userLoading || userDocLoading,
        updatePreferences: updatePreferences
    }
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const RequireAuth = (
    {
        children
    }: {
        children: JSX.Element
    }) => {

    const { currentUser, loading } = useContext(AuthContext);
    const location = useLocation()

    if (loading) {
        return <div>Loading</div>
    }
    else if (!currentUser) {
        return <Navigate to="/login" />
    }
    else if (!currentUser.displayName && location.pathname !== "/whatsyourname") {
        return <Navigate to="/whatsyourname" />
    }

    return children;
}

export default App;
