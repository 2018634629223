import { useContext, useEffect, useRef, useState } from "react";
import { VideoOptions } from "./VideoOptions";
import { VideoGradient } from "../Gradient/VideoGradient";
import { SettingsRow } from "../LandingPage/LandingDeepDive";
import { MeditationMusic, TRACK } from "../MeditationMusic";
import { AuthContext } from "../../App";
import { useParams } from "react-router";
import { Meditation, MeditationSegment, SegmentType } from "../Meditation";
import { useMeditation } from "../../lib/useMeditation";
import { DocumentData } from "firebase/firestore";
import { functions } from "../../firebase";
import { PrimaryButton } from "../UI";
import { httpsCallable } from "firebase/functions";
import { useGeneratedSegments } from "../../lib/useGeneratedSegments";
import { MeditationPlayer2 } from "../MeditationPlayer2";

export const VideoPlayer = () => {
    const [videoOptions, setVideoOptions] = useState<VideoOptions>({
        introText: "Sit is a meditation app that creates custom, personalized meditations.",
        journalText: "",
        track: TRACK.NONE,
        theme: "",
        goal: "",
        durationOptions: ["5","3","2"]
    });

    const [play, setPlay] = useState(false);
    const [playMeditation, setPlayMeditation] = useState(false);

    const {currentUser} = useContext(AuthContext);
    const meditationDocId = useParams().entryId;
    const [musicPlaying, setMusicPlaying] = useState(false);

    const generatedSegments: Map<number, string> | undefined = useGeneratedSegments(currentUser.uid, meditationDocId);

    const {value: initialMeditation, loading: loadingInitialMeditation} = useMeditation(meditationDocId ? meditationDocId : "", currentUser.uid);
    
    const [currentIndex, setCurrentIndex] = useState(0);
    
    const start = () => {
        
        setTimeout(() => {
            setPlay(play => !play)
            setMusicPlaying(play => !play)
        }, 4000);
    }

    if (!meditationDocId || !currentUser) return <div>Error</div>;

    return <div>
        <div className="container mx-auto">
            
            {generatedSegments && generatedSegments.size === 0 && currentUser.uid && meditationDocId &&
                <PrimaryButton onClick={() => {generateMeditation(currentUser.uid, meditationDocId)}}>
                    Generate Audio Segments
                </PrimaryButton>
            }
            <div>Meditation Data</div>

            {initialMeditation && initialMeditation.segments && <div>
                
                {initialMeditation.segments.map((segment, index) =>
                    <div className="flex flex-row space-x-2">
                        <div>segment {index}</div>
                        <div>{segment.type === SegmentType.PAUSE ? "pause" : "speech"}</div>
                        {segment.type === SegmentType.SPEECH &&
                        <div>
                            {generatedSegments && generatedSegments.get(index) ? generatedSegments.get(index) : "not generated"}
                        </div>}
                    </div>
                    )}
                </div>}

            <div className="font-bold">Generated Segments</div>

            <VideoOptions options={videoOptions} setOptions={setVideoOptions} />        

            <MeditationMusic isPlaying={musicPlaying} />

        </div>

        <div className="underline text-2xl py-8 relative z-20 cursor-pointer pb-36"
                onClick={start}> {play ? "Pause": "Play"}</div>
        
        {
            initialMeditation && initialMeditation.segments && generatedSegments && generatedSegments.size === initialMeditation.segments.filter((segment) => segment.type === SegmentType.SPEECH).length &&
                <Player 
                    videoOptions={videoOptions} 
                    play={play}
                    onDone={() => {setPlayMeditation(true)}}
                    segments={initialMeditation.segments}
                    generatedSegments={generatedSegments}
                    playMeditation={playMeditation}
                    currentIndex={currentIndex}
                    meditation={initialMeditation}
                    setCurrentIndex={setCurrentIndex}
                    />
        }
       
    </div>
}
export const Player = (
    {
        videoOptions,
        play,
        onDone,
        segments, 
        generatedSegments,
        playMeditation,
        currentIndex,
        meditation,
        setCurrentIndex,
    } : {
        videoOptions: VideoOptions,
        play: boolean,
        onDone: () => void,
        segments: MeditationSegment[],
        generatedSegments: Map<number, string>,
        playMeditation: boolean,
        currentIndex: number,
        meditation: Meditation,
        setCurrentIndex: (index: number) => void,
    }
) => {

    const [step, setStep] = useState(0);
    const [cutoff, setCutoff] = useState(0);

    useEffect(() => {
        if (!play) {
            setStep(0);
        }
    }, [play])

    useEffect(() => {
        switch(step) {
            case 3:
                setTimeout(() => {
                    setStep(4);
                }, 1500);
                return;
            
            case 4:
                onDone();
        }
    }, [step])

    return <div className={`relative w-full font-raleway flex flex-row space-x-16 justify-start text-white `}>
        <VideoGradient animate={step >= 4} />
        
        {generatedSegments && meditation && 
            <MeditationPlayer2 
                meditation={meditation}
                generatedSegments={generatedSegments}
                forcePlay={playMeditation}
                onSegmentStart={(index: number) => {setCurrentIndex(index)}}/>}

        <div className={`relative z-20 flex flex-col space-y-8 w-1/3 ml-24 transition-all duration-500 ${step >=4 ? "opacity-20" : "opacity-100"}`}>
            <div className="text-4xl mt-64">
                <AnimatedText 
                    text={videoOptions.introText} 
                    animate={play}
                    onDone={() => {
                        setTimeout(() => {setStep(1)}, 500);
                    }}/>
            </div>
            <div>
                <div className="font-bold mb-1 ">
                    <AnimatedText 
                        text="What's on your mind?" 
                        animate={step >= 1}
                        onDone={() => {
                            setTimeout(() => {setStep(2)}, 500);
                        }}
                        />
                    </div>
                <div className={`text-4xl border p-4 rounded-xl transition-all duration-500 ${step >= 2 ? "opacity-100" : "opacity-0"}`}>
                    <AnimatedText 
                        text={videoOptions.journalText}
                        animate={step >= 2}
                        onDone={() => {
                            setTimeout(() => {setStep(3)}, 500);
                        }} />

                </div>
            </div>
            <div className={`transition-all duration-500 ${step >= 3 ? "opacity-100" : "opacity-0"}`}>
                <SettingsRow 
                    goalOptions={["relax", "sleep", "focus", videoOptions.goal]}
                    themeOptions={videoOptions.theme.length > 0 ? ["Rainforest", "Space", "Countryside", videoOptions.theme] : []}
                    durationOptions={videoOptions.durationOptions}
                    animate={step >= 3}
                    />
            </div>
        </div>

        <div className="w-1/2 mt-64 text-white relative z-20">
            <Captions
                show={playMeditation}
                segments={segments}
                currentIndex={currentIndex} />
        </div>
        
    </div>
}

const AnimatedText = (
    {
        text,
        animate,
        onDone,
    } : {
        text: string,
        animate: boolean,
        onDone: () => void,
    }
) => {

    const [showIndex, setShowIndex] = useState(0);

    const SHOW_STYLE = "opacity-100";
    const HIDE_STYLE = "opacity-0 -mt-[5px]";

    console.log(showIndex)
    const textToSpans = (text: string) => {
        return text.split("").map((word, i) => {
            return <span className={`transition-all duration-1000 ${showIndex > i ? SHOW_STYLE : HIDE_STYLE}`} 
                key={i}>{word}</span>
        })
    }

    useEffect(() => {
        if (animate) {
            setShowIndex(showIndex => showIndex + 1);
        } else {
            setShowIndex(0);
        }
    }, [animate])

    useEffect(() => {
        if (!animate) return;

        if (showIndex <= text.length) {  
            setTimeout(() => {
                setShowIndex(showIndex => showIndex + 1);
            }, 30)
        } else {
            onDone();
        }
    }, [showIndex])

    return <div>
        {textToSpans(text)}
    </div>
}

const Captions = (
    {
        segments,
        currentIndex,
        show,
    } : {
        segments: MeditationSegment[],
        currentIndex: number,
        show: boolean,
    }
) => {

    const captionsRef = useRef<Array<HTMLElement | null>>([]);
    const viewRef = useRef(null);

    useEffect(() => {
        if (captionsRef.current[currentIndex]) {
            console.log(captionsRef.current[currentIndex]?.offsetTop)
            if (viewRef.current) {
                (viewRef.current as any).style.transform = `translateY(-${captionsRef.current[currentIndex]?.offsetTop}px)`;
            }
        }

    }, [currentIndex])

    const opacityStyle = (i: number) => {
        if (currentIndex === i) {
            return "opacity-100";
        } else if (i - currentIndex > 8 || i < currentIndex) {
            return "opacity-0";
        } else {
            return "opacity-10"
        }
    }

    return <div ref={viewRef} className={` ${show ? "opacity-100" : "opacity-0"} flex flex-col space-y-4 transition-all duration-1000`}>
        {segments.map((segment, i) => 
            <div 
                className={`transition-all duration-1000 ${opacityStyle(i)} text-xl`}
                key={i} 
                ref={element => captionsRef.current[i] = element}>
                    {segment.type === SegmentType.SPEECH && segment.text}
                </div>)
        }

    </div>
}

export const generateMeditation = (uid: string, meditationDocId: string) => {
    console.log("generating meditation", uid, meditationDocId);

    const generate = httpsCallable(functions, "generateMeditationAudio", {timeout: 60*1000*3});
    return generate({uid: uid, meditationDocId: meditationDocId});
}