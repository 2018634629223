import { useContext } from "react";
import { PrimaryButton } from "../UI";
import { getCheckoutURL, useProducts } from "./billingLib"
import { AuthContext } from "../../App";




export const BuyPremium = () => {

    const [loading, products] = useProducts();
    console.log(products);

    const { currentUser } = useContext(AuthContext);

    const startCheckout = () => {
        if (products && products.length > 0 && products[0].prices && products[0].prices.length > 0)
        getCheckoutURL(currentUser.uid, products[0].prices[0].id);
    }

    return <div className="max-w-[600px] mx-auto">
        <PrimaryButton onClick={startCheckout}>
            Start Subscription
        </PrimaryButton>
    </div>
}