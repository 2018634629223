import { useContext } from "react";
import { MeditationDesigner } from "../components/MeditationDesigner/MeditationDesigner"
import { useRemainingMeditations } from "../lib/useRemainingMeditations"
import { AuthContext } from "../App";
import { Navigate } from "react-router-dom";


export const Create = () => {
    const { currentUser } = useContext(AuthContext);
    const [remaining, loading] = useRemainingMeditations(currentUser.uid);

    if (loading) return <div></div>
    return <div className="max-w-[600px] mx-4 sm:mx-auto">
        {(!loading && remaining && remaining > 0)
            ? <MeditationDesigner />
            : <Navigate to="/home" />}
    </div>
}