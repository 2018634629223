import { collection } from "firebase/firestore";
import { db } from "../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useEffect, useState } from "react";

export const useGeneratedSegments = (uid: string | undefined, meditationDocId: string | undefined): Map<number, string> | undefined => {

    const generatedRef = (uid && meditationDocId) ? collection(db, "users", uid, "meditations", meditationDocId, "generated") : null;
    const [generatedSegments, loading] = useCollectionData(generatedRef);

    const [segments, setSegments] = useState<Map<number, string> | undefined>(undefined);
    
    console.log("segments", segments);

    useEffect(() => {
        
        if (generatedSegments) {
            let newMap = new Map<number, string>();
            generatedSegments.forEach((doc:any) => {
                newMap.set(doc.segment, doc.gcsMetadata.name);
            });
            setSegments(newMap);
        }
    }, [generatedSegments]);

    return segments;

}