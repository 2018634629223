import { Chip } from "../UI"
import { DURATION_OPTIONS } from "./MeditationConfig"

export const DurationPicker = (
    {
        duration,
        setDuration,
    } : {
        duration: number | undefined,
        setDuration: (duration: number) => void,
    }
) => {
    return <div className="flex flex-col space-y-8">
        <div className="w-full text-center text-3xl sm:text-5xl font-garamond">
            Select meditation length:
        </div>
        <div className="flex flex-col sm:flex-row sm:mx-24 items-center space-y-8 sm:space-y-0 sm:space-x-8 ">

        {
            DURATION_OPTIONS.map((durationOption, index) => 
                <Chip 
                    key={index}
                    selected={duration === durationOption}
                    onClick={() => {setDuration(durationOption)}}
                    blinkBeforeClick>
                        {durationOption} min
                </Chip>
            )
        }
        </div>
    </div>
}