import { getSingleChatCompletion } from "../../lib/GPTAPI";
import { DurationPicker } from "./DurationPicker";
import lawrence_sample from "../../audio/lawrence-sample.mp3"
import ira_sample from "../../audio/ira-sample.mp3"

export enum MeditationCategory {
    GRATITUDE = 'Cultivate Gratitude',
    WALK = 'Take a Mindful Walk',
    SLEEP = 'Improve my Sleep',
    RELAX = 'Relax my Body',
    FOCUS = 'Help me Focus',
    MOTIVATION = 'Motivate Me',
    LOVING_KINDNESS = 'Cultivate Compassion',
    ANXIETY = 'Relieve Stress',
}

export type MeditationGoalWithEntry = {
    [key in MeditationCategory]?: string;
};
export type MeditationGoalNoEntry = {
    [key in MeditationCategory]?: string;
};

export type MeditationExplaination = {
    [key in MeditationCategory]?: string;
};

export type MeditationType = {
    [key in MeditationCategory]?: string;
};

export type MeditationQuestion = {
    [key in MeditationCategory]?: string;
};

export type MeditationExample = {
    [key in MeditationCategory]?: string;
};

export const MEDITATION_CATEGORY_OPTIONS = [
    MeditationCategory.GRATITUDE,
    MeditationCategory.FOCUS,
    MeditationCategory.RELAX,
    MeditationCategory.WALK,
    MeditationCategory.SLEEP,
    MeditationCategory.MOTIVATION,
    MeditationCategory.LOVING_KINDNESS,
    MeditationCategory.ANXIETY,

];

export const DURATION_OPTIONS = [3, 5, 7];

export const THEME_OPTIONS = [
        "Glowing Victorian Garden",
        "Floating Sky Castle",
        "Shimmering Night Forest",
        "Soft Moonlit City",
        "Blooming Cherry Blossoms",
        "Golden Sunset Beach",
        "Radiant Sunflower Field",
        "Vibrant Artist Loft",
        "Colorful Graffiti Alley",
        "Glistening City Rooftop",
        "Hushed Library Office",
        "Bustling Farmer's Market",
        "Cozy Bookstore Nook",
        "Outdoor Café Corner",
        "Sleek City Apartment",
        "Serene Zen Office",
        "Quaint Townhouse Charm",
        "Neon Arcade Lights",
        "Charming Floral Shop",
        "Lush Urban Park",
        "Modern Coworking Space",
        "Glowing Skyscraper View",
        "Lively Jazz Club",
        "Industrial Loft",
        "Tranquil Museum Gallery",
        "Sunlit City Balcony",
        "Floating Cloud Castle",
        "Bioluminescent Forest",
        "Whispering Woodlands",
        "Blooming Cherry Canopy",
        "Misty Mountain Summit",
        "Starry Desert Oasis",
        "Underwater Kingdom Glow",
        "Twilight Vineyard",
        "Dewy Dawn Meadow",
        "Sparkling Snowscape",
        "Soothing Seashell Beach",
        "Translucent Cloud Haven",
        "Serene Lily Pond",
];

export enum VoiceOption {
    Lawrence = 'Lawrence',
    Saguna = 'Ira',
}

export const VOICE_OPTION_INFO = {
    [VoiceOption.Lawrence]: {
        name: 'Lawrence',
        voiceId: 'H855HJ4zvFIDwi6JrWdA',
        details: "male, soft, soothing",
        clip: lawrence_sample
    },
    [VoiceOption.Saguna]: {
        name: 'Ira',
        voiceId: 'dF3fuS7TtKxgGjJWof7j',
        details: 'female, kind, nurturing',
        clip: ira_sample
    }
}

export const VOICE_OPTIONS = [VoiceOption.Lawrence, VoiceOption.Saguna];

export const VOICE_OPTION_TO_VOICE_ID = {
    [VoiceOption.Lawrence]: 'H855HJ4zvFIDwi6JrWdA',
    [VoiceOption.Saguna]: 'dF3fuS7TtKxgGjJWof7j',
};

export const MEDITATION_GOALS_ENTRY: MeditationGoalWithEntry = {
  [MeditationCategory.GRATITUDE]: 'fostering a sense of gratitude and appreciation for the positive things mentioned in my life.',
  [MeditationCategory.RELAX]: 'promoting deep relaxation and releasing tension in my body, especially the areas called out in my entry.',
  [MeditationCategory.WALK]: 'practicing mindfulness while walking, connecting with the present moment and the environment mentioned.',
  [MeditationCategory.FOCUS]: 'reducing stress, dealing with difficult emotions and distractions and becoming more present in day-to-day life.',
  [MeditationCategory.SLEEP]: 'helping me fall asleep since I am struggling with sleep.',
  [MeditationCategory.MOTIVATION]: 'cultivating motivation, inspiration and positively affirming myself.',
  [MeditationCategory.LOVING_KINDNESS]: 'developing love for self and others, compassion, and connection and power to navigate complex relationships.',
  [MeditationCategory.ANXIETY]:'relieving my general anxiety and reducing stress expressed in the entry.'
};

export const MEDITATION_GOALS_NO_ENTRY: MeditationGoalNoEntry = {
    [MeditationCategory.GRATITUDE]: 'fostering a sense of gratitude and appreciation for the positive things life.',
    [MeditationCategory.RELAX]: 'promoting deep relaxation and releasing tension in my body.',
    [MeditationCategory.WALK]: 'practicing mindfulness while walking, connecting with the present moment and the environment mentioned.',
    [MeditationCategory.FOCUS]: 'reducing stress, dealing with difficult emotions and distractions and becoming more present in day-to-day life.',
    [MeditationCategory.SLEEP]: 'helping me fall asleep since I am struggling with sleep.',
    [MeditationCategory.MOTIVATION]: 'cultivating motivation, inspiration and setting a positive mindset.',
    [MeditationCategory.LOVING_KINDNESS]: 'developing love for self and others, compassion, and connection.',
    [MeditationCategory.ANXIETY]:'relieving my general anxiety and reducing stress.'

  };

export const MEDITATION_TYPE: MeditationType = {
    [MeditationCategory.GRATITUDE]: 'Gratitude',
    [MeditationCategory.FOCUS]: 'Mindfulness and Concentration',
    [MeditationCategory.RELAX]: 'Body Scan',
    [MeditationCategory.SLEEP]: 'Body Scan focused on Sleep',
    [MeditationCategory.MOTIVATION]: 'Visualization and Affirmation',
    [MeditationCategory.LOVING_KINDNESS]: 'Loving-Kindness',
    [MeditationCategory.ANXIETY]: 'Mindfulness-Based Cognitive Therapy',
};

export const MEDITATION_QUESTION: MeditationQuestion = {
    [MeditationCategory.GRATITUDE]: 'What are you feeling thankful for in your life right now?',
    [MeditationCategory.FOCUS]: 'What are your biggest challenges that may be impacting your focus and mental clarity?',
    [MeditationCategory.RELAX]: 'Do you have any physical discomfort or tension in your body?',
    [MeditationCategory.SLEEP]: 'Can you elaborate on what might be preventing you from experiencing restful sleep?', 
    [MeditationCategory.MOTIVATION]: 'Could you describe any factors or circumstances that might be inhibiting your progress towards achieving your goals?',
    [MeditationCategory.LOVING_KINDNESS]: 'Can you share about any relationships, including the one with yourself, where you would like to foster more compassion and understanding?',
    [MeditationCategory.WALK]: 'What specific intention do you have in mind for your walk today?',
    [MeditationCategory.ANXIETY]: 'What may be causing you stress or anxiety at the moment?'
};

export const MEDITATION_EXPLAIN: MeditationExplaination = {
    [MeditationCategory.GRATITUDE]: 'Practicing gratitude can reorient the mind towards positivity. Sharing what you\'re grateful for, big or small, helps us tailor the meditation experience to your unique perspective.',
    [MeditationCategory.FOCUS]: 'Mindfulness meditation can help improve your focus in everyday activities. By understanding the obstacles to your concentration, we can guide you towards meditations that directly address these challenges.',
    [MeditationCategory.RELAX]: 'A Body-Scan Meditation can help you relax, calm your mind, and become more aware of your body. It can also help us guide you with targeted relaxation techniques based on your physical discomfort.',
    [MeditationCategory.SLEEP]: 'We aim to help you achieve restful sleep by trying to clear your mind and relax your body. By understanding the challenges you\'re facing, we can offer meditations designed to address these specific sleep disruptions',
    [MeditationCategory.MOTIVATION]: 'Visualization meditation is a powerful practice within our app that can help you overcome obstacles hindering your progress towards your goals. By understanding your unique challenges, we can tailor this meditation experience to inspire optimism and boost your motivation.',
    [MeditationCategory.LOVING_KINDNESS]: 'Our Loving-Kindness meditation practice can aid in fostering deep connections, both with others and with oneself. The more we understand about the relationships you\'d like to enhance, the better we can personalize',
    [MeditationCategory.WALK]: 'Walking meditations are an effective way to integrate mindfulness and physical activity in our app. By understanding your intention for each walk, we can guide your mindfulness practice more accurately to help you stay grounded and achieve your mindfulness goals.',
    [MeditationCategory.ANXIETY]: 'Mindfulness meditation can help reduce stress and anxiety in everyday activities. By understanding your current emotions, we can better guide you towards meditations that directly address these challenges.'
};

export const MEDITATION_EXAMPLE: MeditationExample = {
    [MeditationCategory.GRATITUDE]: "e.g. \"Today my dogs playful antics brought a smile to my face\"",
    [MeditationCategory.FOCUS]: "e.g. \"I am frustrated - have many works tasks to finish today but I keep getting distracted \"",
    [MeditationCategory.RELAX]: "e.g. \"I feel tension in my shoulders, possibly from hunching over my computer for long\"",
    [MeditationCategory.SLEEP]: "e.g. \"Need to sleep but nervous about tomorrow's meeting \" ",
    [MeditationCategory.MOTIVATION]: "e.g. \"Feeling unsure and anxious about making the deadline for this upcoming project\"",
    [MeditationCategory.LOVING_KINDNESS]: "e.g. \"My partner and I had an argument and now I am upset\"",
    [MeditationCategory.WALK]: "e.g. \"The weather in San Francisco is great but my mind feels chaotic\"",
    [MeditationCategory.ANXIETY]: "e.g. \"I am stressed about my upcoming house move and everything I have to get done\""
}

export type MeditationConfig = {
    uid: string | undefined;
    username: string | undefined;
    category: MeditationCategory | undefined;
    duration: number | undefined;
    inputText: string | undefined;
    voice: VoiceOption | undefined;
    theme: string | null;
};

export type MeditationConfig2 = {
    uid: string;
    username: string;
    category: MeditationCategory;
    duration: number;
    inputText: string;
    voice: VoiceOption;
};

export const defaultOutput='Output Format: Make it very concise- strictly limit to a few paragraphs with no more than 3 concise lines for each paragraph . Limit the script to 1400 characters in total.  It must be suitable for text-to-speech software to read directly out loud, and include appropriate pauses. Break up paragraphs wherever nature, meditative pauses are needed. No additional headings are required.'

export const MEDITATION_OUTPUT: { [key: number]: string } = {
    3: 'Strict Output: A very high level meditation script fit for an extremely short 2 minute meditation. Structure in 4 very short paragraphs strictly consisting of 3 concise lines each- limit the script to 1500 characters in total . It must be suitable for text-to-speech software to read directly out loud, and include appropriate pauses. Break up paragraphs wherever natural meditative pauses are needed - do not write the word pause. Do not include any additional headings are required (e.g. pause, introduction, closing etc).',
    5: 'Strict Output Format: Make it concise- strictly limit to a few paragraphs with no more than 4 concise lines for each paragraph . Limit the script to 1400 characters in total.  It must be suitable for text-to-speech software to read directly out loud, and include appropriate pauses. Break up paragraphs wherever natural, meditative pauses are needed  - do not write the word pause. Do not include any additional headings are required (e.g. pause, introduction, closing etc).',
    7: 'Strict Output Format: This is a long 8 minute meditation - try to reach 3500 characters. Break up the script into short paragraphs fit with no more than 4 concise lines per paragraph- limit the script to 3500 characters in total. Break up paragraphs wherever long natural, meditative pauses are needed - do not write the word pause. It must be suitable for text-to-speech software to read directly out loud. No additional headings are required. Do not include any additional headings are required (e.g. pause, introduction, closing etc)',
};

export const meditationConfigToScriptPrompt = (meditationConfig: MeditationConfig) => {
    const goalEntry = meditationConfig.category ? MEDITATION_GOALS_ENTRY[meditationConfig.category] : undefined; 
    const goalNoEntry = meditationConfig.category ? MEDITATION_GOALS_NO_ENTRY[meditationConfig.category] : undefined; 
    const type = meditationConfig.category ? MEDITATION_TYPE[meditationConfig.category] : undefined;
    let themePart = meditationConfig.theme ? `The setting is ${meditationConfig.theme} - pick an object typically found in that setting and use it as a 
    metaphor for the desired transformation for the meditation. Include a central question that I can meditate about. ` : '';
    let outputPart = meditationConfig.duration ? MEDITATION_OUTPUT[meditationConfig.duration] : defaultOutput;
    
    //YES JOURNAL YES THEME
    if (meditationConfig.inputText && meditationConfig.theme) {
        console.log("yes journal, yes theme")
    return (
        `You are a meditation teacher. `+
        `Create a custom, unique personalized ${type} meditation to directly tackle my situation in the entry described below. ` +
        `Design this with the goal of ${goalEntry} ${themePart} ` +
        `Directly refer to and address the events, emotions and situation in my entry `+
        `and help me work through any uncomfortable emotions and negative thought patterns the way a kind therapist would. Weave in my name ${meditationConfig.username} in the beginning, once in the middle and the end. ` +
        `Provide a beginning preparing me for meditation and a nice ending thanking me for the practice. `+
        `Here is the entry about the situation: " ${meditationConfig.inputText} " ${outputPart} `);
    //NO JOURNAL NO THEME
    } else if (!meditationConfig.inputText && !meditationConfig.theme) {
        console.log("no journal, no theme")
    return (
        `You are a meditation teacher. ` +
        `Create a custom, personalized ${type} meditation ` +
        `with the goal to ${goalNoEntry} ` +
        `Include a central question or pick an abstract object for me to center my meditation.`+
        `Provide a beginning preparing me for meditation and a nice ending thanking me for the practice. `+
        `Weave in my name ${meditationConfig.username} in the beginning, once in the middle and the end. ${outputPart}` );
    //YES JOURNAL NO THEME
    }   else if (meditationConfig.inputText && !meditationConfig.theme) {
        console.log("yes journal, no theme")
    return (
        `You are a meditation teacher. `+
        `Create a custom, unique personalized ${type} meditation to directly tackle my situation in the entry described below. ` +
        `Design this with the goal of ${goalEntry} ` +
        `Directly refer to and address the events, emotiond and situation in my entry `+
        `and help me work through any uncomfortable emotions and topics the way a kind therapist would. Include a central question relevant to my entry. Weave in my name ${meditationConfig.username} in the beginning, once in the middle and the end. ` +
        `Provide a beginning preparing me for meditation and a nice ending thanking me for the practice. `+ 
        `Here is the entry about the situation: " ${meditationConfig.inputText} " ${outputPart} `);
    //NO JOURNAL YES THEME
    } else {
        console.log("no journal, yes theme")
    return (
        `You are a meditation teacher. `+
        `Create a custom, personalized ${type} meditation ` +
        `with the goal of ${goalNoEntry} ${themePart} ` +
        `Weave in my name ${meditationConfig.username} in the beginning, once in the middle and the end. ` +
        `Provide a beginning preparing me for meditation and a nice ending thanking me for the practice. `+ 
        `${outputPart} `);
    }
}


export const getMeditationScript = (meditationConfig: MeditationConfig) => {
    const prompt = meditationConfigToScriptPrompt(meditationConfig);
    console.log(prompt);
    return getSingleChatCompletion(prompt);
};

export const getMeditationTitle = (meditationConfig: MeditationConfig) => {
    const goal = meditationConfig.category ? MEDITATION_GOALS_ENTRY[meditationConfig.category] : undefined;
    const type = meditationConfig.category ? MEDITATION_TYPE[meditationConfig.category] : undefined;
    let prompt=''
    meditationConfig.theme? 
    prompt= `Create a title for a ${type} meditation with a goal of ${goal} set in ${meditationConfig.theme}  (format as main title: subtitle which is a very concise description (limit to 4 words))` 
    :
    prompt= `Create a title for a ${type} meditation with a goal of ${goal}  (format as main title: subtitle which is a very concise description (limit to 4 words))` 
    return getSingleChatCompletion(prompt);
}


