import { useEffect, useState } from "react"

export const PrimaryButton = (
    {
        onClick,
        disabled,
        children,
        dark
    } : {
        onClick: () => void
        disabled?: boolean
        children?: React.ReactNode
        dark?: boolean
    }
) => {

    const hoverStyle = dark ? "hover:bg-black/80" : "hover:bg-black/10";

    return <div className={`rounded-full px-8 py-2 border border-black transition-all duration-500 text-center
                                ${dark ? "bg-black text-white font-bold" : "text-black"}
                                ${disabled ? 
                                "opacity-50 cursor-not-allowed" 
                                : "cursor-pointer " + hoverStyle}`}
                onClick={() => {if (!disabled) onClick()}}>
        {children}
    </div>
}

export const Chip = (
    {
        onClick,
        selected,
        children,
        blinkBeforeClick,
    } : {
        onClick: () => void
        selected: boolean
        children?: React.ReactNode
        blinkBeforeClick?: boolean
}) => {
    const [blinkOn, setBlinkOn] = useState(false);
    const [startBlink, setStartBlink] = useState(false);

    useEffect(() => {
        if (startBlink) {
            setTimeout(() => {
                setBlinkOn(blinkOn => !blinkOn);
            }, 150)
        }
    }, [blinkOn])

    useEffect(() => {
        if (startBlink) {
            setBlinkOn(true);
        }
    }, [startBlink])

    return <div className={`p-4 px-8 rounded-full border border-black text-center cursor-pointer transition-all duration-100
            ${startBlink ?
                (blinkOn ? "bg-black text-white" : "hover:bg-black/20")
                : (selected ? "bg-black text-white" : "hover:bg-black/20")}`}
            
            onClick={() => {
                if (blinkBeforeClick) {
                    setStartBlink(true);
                    setTimeout(() => {
                        setStartBlink(false);
                    }, 400);
                }
                onClick();
            }}>
        {children}
    </div>
}