import React from 'react';

const Feature= ({ heading, subHeading }: { heading: string, subHeading: string }) => (
  <div className="flex flex-col text-center items-center justify-center p-8 font-raleway">
    <h2 className="text-3xl mb-4">{heading}</h2>
    <p className="text-lg">{subHeading}</p>
  </div>
);


export const LandingFeatures = ( ) => (
  <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-12 py-24">
      <Feature heading={"Personalized Pace and Place"} subHeading={"Short on time or seeking a longer session? You choose the length. From tranquil lily ponds to floating sky castles, pick or state the setting that resonates with you."} />
      <Feature heading={"Meets You Where You Are"} subHeading={"Tell us a little about what is going on for you to tailor your meditation. No matter your mood or circumstance, we'll guide you to tranquility. "} />
      <Feature heading={"Custom Calm for All"} subHeading={"We are designed to be user-friendly for meditation beginners and expansive for experts. Cultivate a meditation practice that evolves with you."} />
  </div>
  
);