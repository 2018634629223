
// The meditation player takes in a meditation db reference
// It can handle meditations with any amount of segments generated
// It updates it's internal Meditation with the latest data
// It also saves to the db the latest data

import { useCallback, useEffect, useState } from "react"
import { Meditation, MeditationSegment, SegmentType, getPauseDurationSeconds } from "./Meditation"
import { useMeditation } from "../lib/useMeditation"
import { createSilentAudio } from "create-silent-audio"
import { useTimer } from "../lib/useTimer"
import { PauseCircle, PlayCircle } from "./icons"
import { PrimaryButton } from "./UI"
import { useNavigate } from "react-router-dom"
import { MeditationMusic } from "./MeditationMusic"
import finishedtone from "../audio/finishedTone.mp3"
import { getDownloadURL, ref } from "firebase/storage"
import { storage } from "../firebase"


export type PlayableMeditationSegment = {
    segment: MeditationSegment,
    audio: HTMLAudioElement | undefined,
}

export const initAudioSegments = (
    meditation: Meditation,
    generatedSegments: Map<number, string>
): PlayableMeditationSegment[] => {
    
    return meditation.segments.map((segment, index) => {
        return segmentToPlayableSegment(index, segment, meditation, generatedSegments);
    });
}

const segmentToPlayableSegment = (
    index: number,
    segment: MeditationSegment, 
    meditation: Meditation,
    generatedSegments: Map<number, string>
): PlayableMeditationSegment => {

    if (segment.type === SegmentType.PAUSE) {
        return {
            segment: segment,
            audio: new Audio(createSilentAudio(getPauseDurationSeconds(segment, meditation)))
        }
    } else if (segment.type === SegmentType.SPEECH) {
        let newAudio = new Audio();
        getDownloadURL(ref(storage, generatedSegments.get(index)))
            .then((url) => {
                newAudio.src = url;
            })
            .catch((error) => {
                console.log("no download url for segment", index, generatedSegments.get(index));
            });

        return {
            segment: segment,
            audio: newAudio
        };
    } else {
        return {
            segment: segment,
            audio: undefined
        }
    }
}

export const MeditationPlayer2 = (
    {
        meditation,
        generatedSegments,
        forcePlay,
        onSegmentStart,
        onDone,
    } : {

        meditation: Meditation,
        generatedSegments: Map<number, string>,
        forcePlay?: boolean,
        onSegmentStart?: (index: number) => void,
        onDone?: () => void
    }
) => {
    const segments = meditation.segments;

    const [audioSegments, setAudioSegments] = useState<PlayableMeditationSegment[]>([]);
    
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [playIndex, setPlayIndex] = useState<number>(0);
    const [hidden, setHidden] = useState<boolean>(true);

    const {elapsed, elapsedDisplay, start: startTimer, pause: pauseTimer} = useTimer();
    const [finished, setFinished] = useState<boolean>(false);
    const navigate = useNavigate();

    
    const advanceToNextSegment = () => {
        if (!isPlaying) return;
        if (playIndex < segments.length - 1) {
            setPlayIndex(playIndex => playIndex + 1);
        }
        else {
            setIsPlaying(false);
            setFinished(true);
            onDone && onDone();
        }
    }

    useEffect(() => {
        setAudioSegments(initAudioSegments(meditation, generatedSegments));
    }, []);

    useEffect(() => {
        if (forcePlay) {
            setIsPlaying(true);
        }
    }, [forcePlay]);

    useEffect(() => {
        if (playIndex < 0) return;
        if (!audioSegments || audioSegments.length === 0) return;

        const currentAudio = audioSegments[playIndex].audio;
        if (!currentAudio) return;
        
        if (isPlaying) {
            currentAudio.play();
            startTimer();
            onSegmentStart && onSegmentStart(playIndex);
            currentAudio.onended = advanceToNextSegment;
        } else {
            currentAudio.pause();
            pauseTimer();
        }

        return () => {
            currentAudio.pause();
        }
    }, [isPlaying, playIndex]);

    if (hidden) return <div/>;

    return <div className="max-w-[400px] mx-auto flex flex-col items-center text-center space-y-2 min-h-[100vh]">

        {finished 

            ? <div>  
                <PrimaryButton onClick={() => {navigate('/home')}} >Return Home</PrimaryButton>
                <div className="p-10 text-sm"><p>
                    Thank you for using Sit. <br /> 
                    How was that meditation for you?</p></div>
                <div className="flex justify-center space-x-20">
            
        </div>
            </div>

            : <div className="flex flex-col items-center">
                {meditation.config.duration && <div className="text-lg font-bold pb-8">{meditation.config.duration} min</div> }

                <button className="text-2xl" onClick={() => setIsPlaying(!isPlaying)}>
                    {isPlaying ? PauseCircle : PlayCircle}
                </button>
                
                <div className="w-full text-center">
                    {elapsedDisplay}
                </div>

                <div className="h-8" />

                <MeditationMusic isPlaying={isPlaying}/>

            </div>}
        
    </div>
}

export const MeditationTitleCard = (
    {
        title,
    } : {
        title: string | undefined,
}) => {

    let removeQuotes = title?.replaceAll(`"`, "");
    let subtitle = removeQuotes;
    if (removeQuotes) {
        if (removeQuotes.split(":").length > 1) {
            subtitle = removeQuotes.split(":")[1];
        }
    }

    return <div>
        <div className="text-6xl font-garamond mb-8">{removeQuotes?.split(":")[0]}</div>
        <div className="text-3xl font-garamond mb-8">{subtitle}</div>
    </div>
}