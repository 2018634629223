import { useEffect, useRef, useState } from "react";
import { SimpleSketch } from "./SimpleSketch";
import { pickGradientColors, randomLightPalette } from "./Palette";
import * as THREE from "three";

export const VideoGradient = (
    {
        animate,
    } : {
        animate: boolean,
    }
) => {
    const ref = useRef(null);
    const [sketch, setSketch] = useState<SimpleSketch | null>(null);
    const [cutoff, setCutoff] = useState<number>(0);

    useEffect(() => {
        if (animate) {
            setCutoff(0.151);
        } else {
            setCutoff(0.15);
        }
    }, [animate])

    useEffect(() => {
        if (sketch) sketch.setCutoff(cutoff);

        if (!animate) return;
        
        if (cutoff <= 1) {
            setTimeout(() => {
                setCutoff(cutoff => cutoff + 0.01);
            }, 50);
        }
    }, [cutoff])

    useEffect(()  => {
        if (ref.current) {
            const palette = pickGradientColors(randomLightPalette());
            console.log(palette);
            const params = {
                time: Math.random(),
                colorFreq: [
                    2,
                    2]
            }
            const sketch = new SimpleSketch(ref.current,  params, palette, new THREE.Color(`#000000`),)
            sketch.setCutoff(0.1 + window.scrollY / window.innerHeight);
            setSketch(sketch);
        }
    }, [])

    return <div className={`absolute top-0 left-0 w-full h-[110vh] `} ref={ref} />
    
}