import { MeditationConfig } from "./MeditationDesigner/MeditationConfig"

export type MeditationSegment = PauseSegment | SpeechSegment

export enum SegmentType {
    PAUSE,
    SPEECH
}

export type PauseSegment = {
    type: SegmentType.PAUSE,
    factor: number,
}

export type SpeechSegment = {
    type: SegmentType.SPEECH,
    text: string,
    storageUrl?: string | undefined,
}

export type Meditation = {
    docId?: string,
    uid: string,
    dateCreated?: Date,
    title?: string,
    config: MeditationConfig,
    segments: MeditationSegment[],
    thumbsUp?: number;
    thumbsDown?:number;
}

export const scriptToMeditation = (
    uid: string,
    script: string,
    config: MeditationConfig,
    title: string
): Meditation => {
    let segments: MeditationSegment[] = [];
    const paragraphs = script.split('\n');
    
    paragraphs.forEach((paragraph, index) => {
        if (!ignoreLine(paragraph)) {
            let sentences = paragraph.split('.');

            sentences.forEach((sentence, index) => {
                if (sentence.length < 10) return;

                segments.push({
                    type: SegmentType.SPEECH,
                    text: sentence.trim() + "."
                });

                segments.push({
                    type: SegmentType.PAUSE,
                    factor: 1
                })
            });
            segments.pop(); // pop last 1x pause
            
            segments.push({
                type: SegmentType.PAUSE,
                factor: 5
            })
        }
        
    });
    segments.pop(); // pop last 5x second pause

    segments.push({
        type: SegmentType.PAUSE,
        factor: 2
    })

    return {
        uid: uid,
        segments: segments,
        config: config,
        title: title,
    }
}

const ignoreLine = (line: string):boolean => {
    if (line.length === 0) return true; // empty lines
    else if (line.split(" ").length <= 2) return true; // one word lines
    return false;
}

export const getSegmentStoragePath = (
    meditation: Meditation,
    index: number
) => {

    return `users/${meditation.uid}/meditations/${meditation.docId}/segment${index}.mp3`;
}

export const getPauseDurationSeconds = (
    segment: PauseSegment,
    meditation: Meditation,
) => {

    const duration = getPauseDurationSecondsDetailed(
        meditation.config.duration? meditation.config.duration * 60 : 5 * 60,
        getEstimatedSpeechDurationSeconds(meditation),
        getTotalPauseFactor(meditation),
        segment
    )
    return Math.max(duration, 1);
}

export const getPauseDurationSecondsDetailed = (
    targetTotalTime: number,
    estimatedSpeechTime: number,
    totalFactor: number,
    segment: PauseSegment,

) => {
    return Math.floor(segment.factor / totalFactor * (targetTotalTime - estimatedSpeechTime));
}

export const getEstimatedSpeechDurationSeconds = (meditation: Meditation) => {
    let duration = 0;
    meditation.segments.forEach((segment) => {
        if (segment.type === SegmentType.SPEECH) {
            duration += segment.text.length *.06;
        }
    });
    return duration;
}

export const getTotalPauseFactor = (meditation: Meditation) => {
    let factor = 0;
    meditation.segments.forEach((segment) => {
        if (segment.type === SegmentType.PAUSE) {
            factor += segment.factor;
        }
    });
    return factor;
}