import { httpsCallable } from "firebase/functions";
import { functions, storage } from "../firebase";
import { getDownloadURL, ref } from "firebase/storage";

export const textToSpeechURL = async (
    fileName: string,
    textInput: string,
    voiceId: string,
) => {
    const tts = httpsCallable(functions, "textToSpeechSave");
    const response = await tts({
        voiceId: voiceId ? voiceId : 'dF3fuS7TtKxgGjJWof7j',
        fileName: fileName,
        textInput: textInput,
        stability: 0.6,
    })
    .catch((error) => {
        console.log(error);
    });
    
    if (response) {
        const uploadedURL = (response.data as any)[0].name;
        const pathReference = ref(storage, uploadedURL);
        return getDownloadURL(pathReference);
    }
    else {
        return undefined;
    }
}

export const textToSpeechMock = async (
    fileName: string,
    textInput: string,
    voiceId?: string,
) => {
    return new Promise<string>((resolve, reject) => {
        setTimeout(() => {
            resolve("https://firebasestorage.googleapis.com/v0/b/meditation-fd98c.appspot.com/o/users%2Fuid%2Fmeditations%2Fmeditation3%2Fsegment0.mp3?alt=media&token=8b409845-6237-4c39-8ebe-1a93800c0d4c");
        }, 500);
    });
}