import { Timestamp, collection, query, where } from "firebase/firestore";
import { useCollectionDataOnce, useCollectionOnce } from "react-firebase-hooks/firestore";
import { db } from "../firebase";

export const useRemainingMeditations = (uid: string): [number | null, boolean] => {
    var today = new Date();
    today.setUTCHours(0,0,0,0);
    const todayTimestamp = Timestamp.fromDate(today);
    
    const q = query(collection(db, "users", uid, "meditations"), where("dateCreated", ">=", todayTimestamp));
    const [values, loading, error, snapshot] = useCollectionDataOnce(q);
    
    return [
        values ? 2 - values.length : null,
        loading];

}