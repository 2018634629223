import React, { useState } from 'react';
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../components/UI';


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const onGoogleLogin = () => {
        signInWithPopup(auth, new GoogleAuthProvider()).then((result) => {
            navigate("/home");
            console.log(result)
        }).catch((error) => {
            console.log(error);
        });
    }

    const onEmailLogin = () => {

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                console.log(userCredential.user)
                navigate("/home");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
    };
    

    return (
        <div className="container mx-auto flex flex-col items-center min-h-[100vh]">
            <div className="text-4xl font-garamond mb-4 mt-24 text-center w-full">Login </div>    
            <div className="w-[300px] ">
                <form className="flex flex-col space-y-4  p-4  shadow-lg rounded-lg bg-white" onSubmit={onEmailLogin}>
                    <div className="w-full">
                        <label htmlFor="email-address">Email address</label>
                        <input
                            className="border border-gray-400 w-full p-2 rounded-md text-black"
                            id="email-address"
                            name="email"
                            type="email"
                            required
                            placeholder="Email address"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="w-full">
                        <label htmlFor="password">Password</label>
                        <input
                            className="border border-gray-400 w-full p-2 rounded-md text-black"
                            id="password"
                            name="password"
                            type="password"
                            required
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <PrimaryButton onClick={onEmailLogin}>
                        Login
                    </PrimaryButton>
                    <div className="w-full flex flex-row items-center justify-center space-x-2 text-xs text-neutral-500 px-4">
                        <div className="w-full h-[1px] rounded-full bg-neutral-500" />
                        <div>
                            or
                        </div>
                        <div className="w-full h-[1px] bg-neutral-500" />
                    </div>
                    <PrimaryButton onClick={onGoogleLogin}>
                        Login with Google
                    </PrimaryButton>
                    
                    <div className="text-sm w-full text-center">
                    No account yet?{' '}
                        <span className="underline cursor-pointer" onClick={() => { navigate('/signup') }}>
                            Sign up
                        </span>
                    </div>
                </form>

                
            </div>
        </div>
    );
};

export { Login };
