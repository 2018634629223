import { useCollectionDataOnce } from "react-firebase-hooks/firestore"
import { Meditation } from "../components/Meditation"
import { collection, orderBy, query } from "firebase/firestore"
import { db } from "../firebase"
import { meditationConverter } from "./useMeditation"

export const useRecentMeditations = (
    uid: string
) => {

    const ref = collection(db, "users", uid, "meditations").withConverter(meditationConverter);
    const q = query(ref, orderBy("dateCreated", "desc"));

    const [values, loading, error, snapshot] = useCollectionDataOnce<Meditation>(q);
    return {loading, values, error};
}