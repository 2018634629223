import { useEffect, useState } from "react";
import { createMeditation } from "../../lib/useMeditation";
import { scriptToMeditation } from "../Meditation";
import { MeditationPlayer, MeditationTitleCard } from "../MeditationPlayer";
import { MeditationConfig, getMeditationScript, getMeditationTitle } from "./MeditationConfig";

export const LoadingMeditation = (
    {
        meditationConfig
    } : {
        meditationConfig: MeditationConfig
    }
) => {
    const [script, setScript] = useState<string | undefined>(undefined);
    const [title, setTitle] = useState<string | undefined>(undefined);
    const [ready, setReady] = useState<boolean>(false);
    const [meditationId, setMeditationId] = useState<string | undefined>(undefined);

    useEffect(() => {
        getMeditationScript(meditationConfig)
            .then((script) => {
                console.log(script)
                setScript(script);
            })
            .catch((error) => {
                console.log(error);
            });

        getMeditationTitle(meditationConfig)
            .then((title) => {
                setTitle(title);
            })
            .catch((error) => {
                console.log(error)
            })
    }, []);

    useEffect(() => {
        if (title && script && meditationConfig.uid) {
            let meditation = scriptToMeditation(meditationConfig.uid, script, meditationConfig, title);
            //let meditation = scriptToMeditation(meditationConfig.uid, sampleScriptShort);
            
            createMeditation(meditationConfig.uid, meditation)
                .then((docRef) => {
                    console.log("Meditation created", meditation.segments )
                    setMeditationId(docRef.id);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [script])

    return <div>
        {!ready && <Loader title={title} duration={meditationConfig.duration}/>}

        {meditationConfig.uid && meditationId && 
            <MeditationPlayer 
                uid={meditationConfig.uid} 
                meditationId={meditationId}
                onReady={() => {setReady(true)}} />
        }
        
    </div>
}

export const Loader = (
    {
        title,
        duration
    } : {
        title: string | undefined,
        duration: number | undefined
    }
) => {
    return <div className="flex flex-col items-center">
            <div className="max-w-[400px] flex flex-col space-y-8 text-center">
                {(title && duration) && <MeditationTitleCard title={title}/> }

                <div className="flex flex-col justify-center items-center">
                    <div className="loader w-[50px] h-[50px]"></div>
                </div>
                <div className="text-md">
                    Generating your custom meditation...
                </div>
            </div>
    </div>
}