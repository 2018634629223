import { useState } from "react";

export const useTimer = () => {

    const [elapsed, setElapsed] = useState(0);
    const [timer, setTimer] = useState<NodeJS.Timer | undefined>(undefined);

    const start = () => {
        if (!timer) {
            const t = setInterval(() => {
                setElapsed(elapsed => elapsed + 1);
            }, 1000);
            setTimer(t);
        }
    }

    const pause = () => {
        if (timer) {
            clearInterval(timer);
            setTimer(undefined);
        }
    }

    const elapsedDisplay = `${Math.floor(elapsed / 60)}:${elapsed % 60 < 10 ? "0" : ""}${elapsed % 60}`

    return {elapsed, elapsedDisplay, start, pause}
}