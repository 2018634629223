import { useState } from "react"
import { TRACK, TRACK_OPTIONS } from "../MeditationMusic"

export type VideoOptions = {
    introText: string,
    journalText: string,
    track: TRACK,
    theme: string,
    goal: string,
    durationOptions: string[]
}

export const VideoOptions = (
    {
        options,
        setOptions
    } : {
        options: VideoOptions,
        setOptions: (options: VideoOptions) => void
    }
) => {

    return <div className="container mx-auto flex flex-col space-y-4">
        <div className="text-2xl py-8">
            Video Options
        </div>
        <div>
            <label>intro text</label>
            <input className="p-2 mx-2 w-full" type="text" value={options.introText} onChange={(e) => {setOptions({...options, introText: e.target.value})}}></input>
        </div>
        <div>
            <label>journal text</label>
            <input className="p-2 mx-2 w-full" type="text" value={options.journalText} onChange={(e) => {setOptions({...options, journalText: e.target.value})}}></input>
        </div>
        
        <div>
            <label>theme name</label>
            <input className="p-2 mx-2 w-full" type="text" value={options.theme} onChange={(e) => {setOptions({...options, theme: e.target.value})}}></input>
        </div>
        <div>
            <label>goal name</label>
            <input className="p-2 mx-2 w-full" type="text" value={options.goal} onChange={(e) => {setOptions({...options, goal: e.target.value})}}></input>
        </div>
    </div>

}