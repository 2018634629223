import {useDocumentDataOnce} from 'react-firebase-hooks/firestore';
import { Meditation } from '../components/Meditation';
import { db } from '../firebase';
import { FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, addDoc, collection, doc } from 'firebase/firestore';

export const meditationConverter: FirestoreDataConverter<Meditation> = {
    toFirestore(meditation: Meditation): Meditation {
        return {
            ...meditation
        }
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ) : Meditation {
        const data = snapshot.data(options);
        return {
            docId: snapshot.id,
            uid: data.uid,
            segments: data.segments,
            config: data.config,
            dateCreated: data.dateCreated ? data.dateCreated.toDate() : undefined,
            title: data.title,
            thumbsUp: data.thumbsUp || 0,
            thumbsDown: data.thumbsDown || 0
        }
    }
}

export const useMeditation = (
    meditationDocId: string,
    uid: string,
) => {
    const ref = doc(db, "users", uid, "meditations", meditationDocId).withConverter(meditationConverter);
    const [value, loading, error] = useDocumentDataOnce<Meditation>(ref);

    return {
        value: value,
        loading: loading,
    }
}

export const createMeditation = (
    uid: string,
    meditation: Meditation
) =>{
    return addDoc(collection(db, "users", uid, "meditations"), {
        dateCreated: new Date(),
        ...meditation 
    });
}