export const LandingNavbar = () => {

    return <div className="relative w-full flex flex-row items-center justify-between py-4 px-8 z-20">
        <div className="flex flex-row">
            <div className="raleway text-lg hidden sm:block">
                HOW IT WORKS
            </div>
        </div>
        <div className="flex flex-row">
            <div className="font-mak text-2xl">
                Sit
            </div>
        </div>
        <div className="flex flex-row">
            <div className="raleway text-lg hidden sm:block">
            <a href="https://tazs7mxpndu.typeform.com/to/cxJTFPuZ">GET EARLY ACCESS</a>
            </div>
        </div>
    </div>
}