import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { db }  from '../firebase';

const createUser = async (uid: string) => {
    try {
        const docRef = await setDoc(doc(db, "users", uid), {
            username: "",
        });

        console.log("Document written with ID: ", docRef);

    } catch(e) {
        console.error("Error adding document: ", e)
    }
}

export {createUser}