
// The meditation player takes in a meditation db reference
// It can handle meditations with any amount of segments generated
// It updates it's internal Meditation with the latest data
// It also saves to the db the latest data

import { useCallback, useEffect, useState } from "react"
import { textToSpeechURL } from "../lib/TextToSpeech"
import { Meditation, MeditationSegment, SegmentType, getPauseDurationSeconds, getSegmentStoragePath } from "./Meditation"
import { useMeditation } from "../lib/useMeditation"
import { createSilentAudio } from "create-silent-audio"
import { doc, updateDoc } from "firebase/firestore"
import { db } from "../firebase"
import { useTimer } from "../lib/useTimer"
import { PauseCircle, PlayCircle } from "./icons"
import { PrimaryButton } from "./UI"
import { useNavigate } from "react-router-dom"
import { MeditationMusic } from "./MeditationMusic"
import { VOICE_OPTION_TO_VOICE_ID } from "./MeditationDesigner/MeditationConfig"
import finishedtone from "../audio/finishedTone.mp3"
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import { submitFeedback } from "../lib/submitFeedback"


export type PlayableMeditationSegment = {
    segment: MeditationSegment,
    audio: HTMLAudioElement | undefined,
}

export const initAudioSegments = (
    meditation: Meditation
): PlayableMeditationSegment[] => {
    
    return meditation.segments.map((segment) => {
        return segmentToPlayableSegment(segment, meditation);
    });
}

const segmentToPlayableSegment = 
    (segment: MeditationSegment, meditation: Meditation): PlayableMeditationSegment => {
    if (segment.type === SegmentType.PAUSE) {
        return {
            segment: segment,
            audio: new Audio(createSilentAudio(getPauseDurationSeconds(segment, meditation)))
        }
    } else if (segment.type === SegmentType.SPEECH) {
        const newAudio = segment.storageUrl ? new Audio(segment.storageUrl) : undefined;
        if (newAudio) newAudio.playbackRate = 1;

        return {
            segment: segment,
            audio: newAudio
        };
    } else {
        return {
            segment: segment,
            audio: undefined
        }
    }
}

export const generateNextSpeechSegment = (
    meditation: Meditation,
    newSegment: (i: number, segment: MeditationSegment) => void
) => {
    const {segment, index} = nextSpeechSegmentToGenerate(meditation.segments);
    
    if (segment) {
        const storagePath = getSegmentStoragePath(meditation, index);
        console.log("GENERATING", storagePath);
        const voiceId = meditation.config.voice ? VOICE_OPTION_TO_VOICE_ID[meditation.config.voice] : VOICE_OPTION_TO_VOICE_ID["Lawrence"];
        textToSpeechURL(storagePath, segment.text, voiceId)
            .then((url) => {
                if (url) {
                    newSegment(index, {
                        ...segment,
                        storageUrl: url,
                    })
                }
            })
    }
}

export const nextSpeechSegmentToGenerate = (
    segments: MeditationSegment[]
) => {
    for (var i = 0; i < segments.length; i++) {
        let segment = segments[i];
        if (segment.type === SegmentType.SPEECH && segment.storageUrl === undefined) {
            console.log("no storage url", i, segments)
            return {segment: segment, index: i}
        }
    }
    return {segment: undefined, index: -1};
}

export const MeditationPlayer = (
    {
        meditationId,
        uid,
        onReady,
        forcePlay,
        onSegmentStart,
        onDone,
    } : {
        meditationId: string,
        uid: string,
        onReady: () => void,
        forcePlay?: boolean,
        onSegmentStart?: (index: number) => void,
        onDone?: () => void
    }
) => {
    const {value: initialMeditation, loading: loadingInitialMeditation} = useMeditation(meditationId, uid);
    const [segments, setSegments] = useState<MeditationSegment[]>([]);
    const [audioSegments, setAudioSegments] = useState<PlayableMeditationSegment[]>([]);
    
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [playIndex, setPlayIndex] = useState<number>(0);
    const [hidden, setHidden] = useState<boolean>(true);
    const [thumbsUpClicked, setThumbsUpClicked] = useState<boolean>(false);
    const [thumbsDownClicked, setThumbsDownClicked] = useState<boolean>(false);

    const {elapsed, elapsedDisplay, start: startTimer, pause: pauseTimer} = useTimer();
    const [finished, setFinished] = useState<boolean>(false);
    const navigate = useNavigate();

    const [finishedTone, setFinishedTone] = useState<HTMLAudioElement>(new Audio(finishedtone));

    const updateSegment = (index: number, segment: MeditationSegment) => {
        if (!segments || segments.length === 0 || !initialMeditation) return;
        
        setSegments(segments => 
                [...(segments).slice(0, index), segment, ...(segments).slice(index + 1) ]);

        const newAudioSegment = segmentToPlayableSegment(segment, initialMeditation);
        setAudioSegments(audioSegments =>
            [...(audioSegments).slice(0, index), newAudioSegment, ...(audioSegments).slice(index + 1) ]);
    }

    const advanceToNextSegment = useCallback(() => {
        if (!isPlaying) return;
        if (playIndex < segments.length - 1) {
            setPlayIndex(playIndex => playIndex + 1);
        }
        else {
            setIsPlaying(false);
            setFinished(true);
            finishedTone.play();
            onDone && onDone();
        }
    }, [isPlaying, playIndex])

    useEffect(() => {
        if (forcePlay) {
            setIsPlaying(true);
        } else if (forcePlay !== undefined && !forcePlay) {
            setIsPlaying(false);
            setPlayIndex(0);
        }
    }, [forcePlay])

    useEffect(() => {
        if (!loadingInitialMeditation && initialMeditation) {
            console.log("done loading", initialMeditation);
            setAudioSegments(
                initAudioSegments(initialMeditation)
            );

            setSegments(initialMeditation.segments);
        }
    }, [loadingInitialMeditation])

    useEffect(() => {
        if (segments && initialMeditation) {
            const ref = doc(db, "users", uid, "meditations", meditationId);

            updateDoc(ref, {
                segments: segments,
            }).then(() => {
                console.log("firestore updated");
            }).catch((error) => {
                console.log("error updating firestore", error);
            });

            generateNextSpeechSegment({...initialMeditation, segments: segments}, updateSegment);
            const {index} = nextSpeechSegmentToGenerate(segments);
            if (index > 1 || index < 0) {
                setHidden(false);
                onReady();
            }
        }
    }, [segments])

    useEffect(() => {
        if (!audioSegments || audioSegments.length === 0) return;

        const currentAudio = audioSegments[playIndex].audio;
        if (!currentAudio) return;
        
        if (isPlaying) {
            currentAudio.play();
            startTimer();
            onSegmentStart && onSegmentStart(playIndex);
            currentAudio.onended = advanceToNextSegment;
        } else {
            currentAudio.pause();
            pauseTimer();
        }

        return () => {
            currentAudio.pause();
        }
    }, [isPlaying, playIndex]);

    if (hidden) return <div/>;

    //update ratingValue
    const handleThumbsUp = () => {
        setThumbsUpClicked(true);
        setThumbsDownClicked(false);
        submitFeedback(uid, meditationId, true);
      };
      
      const handleThumbsDown = () => {
        setThumbsDownClicked(true);
        setThumbsUpClicked(false);
        submitFeedback(uid, meditationId, false);
      };

    return <div className="max-w-[400px] mx-auto flex flex-col items-center text-center space-y-2 min-h-[100vh]">
        <MeditationTitleCard title={initialMeditation?.title}  />

        {finished 

            ? <div>  
                <PrimaryButton onClick={() => {navigate('/home')}} >Return Home</PrimaryButton>
                <div className="p-10 text-sm"><p>
                    Thank you for using Sit. <br /> 
                    How was that meditation for you?</p></div>
                <div className="flex justify-center space-x-20">
            <button className="text-2xl" onClick={handleThumbsUp}>
                <FaThumbsUp className={thumbsUpClicked ? "text-green-400" : ""}/>
            </button>
            <button className="text-2xl" onClick={handleThumbsDown}>
                <FaThumbsDown className={thumbsDownClicked ? "text-rose-200" : ""}/>
            </button>
        </div>
            </div>

            : <div className="flex flex-col items-center">
                {initialMeditation?.config.duration && <div className="text-lg font-bold pb-8">{initialMeditation?.config.duration} min</div> }

                <button className="text-2xl" onClick={() => setIsPlaying(!isPlaying)}>
                    {isPlaying ? PauseCircle : PlayCircle}
                </button>
                
                <div className="w-full text-center">
                    {elapsedDisplay}
                </div>

                <div className="h-8" />

                <MeditationMusic isPlaying={isPlaying}/>

            </div>}
        
    </div>
}

export const MeditationTitleCard = (
    {
        title,
    } : {
        title: string | undefined,
}) => {

    let removeQuotes = title?.replaceAll(`"`, "");
    let subtitle = removeQuotes;
    if (removeQuotes) {
        if (removeQuotes.split(":").length > 1) {
            subtitle = removeQuotes.split(":")[1];
        }
    }

    return <div>
        <div className="text-6xl font-garamond mb-8">{removeQuotes?.split(":")[0]}</div>
        <div className="text-3xl font-garamond mb-8">{subtitle}</div>
    </div>
}