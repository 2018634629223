import { useContext } from "react";
import { MeditationDesigner } from "../components/MeditationDesigner/MeditationDesigner"
import { AuthContext } from "../App";
import { MeditationHome } from "../components/MeditationHome";

export const Home = () => {

    const { currentUser } = useContext(AuthContext);
    
    return <div className="mx-4 sm:mx-auto pt-24">
        {currentUser ? 
            <MeditationHome />
            : <div>Loading</div>}
    </div>
}