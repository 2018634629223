import { useContext } from "react";
import { AuthContext } from "../App";
import { MeditationDesigner } from "./MeditationDesigner/MeditationDesigner";
import { PrimaryButton } from "./UI";
import { useNavigate } from "react-router-dom";
import { useRecentMeditations } from "../lib/useRecentMeditation";
import { PreviousMeditation } from "./PreviousMeditation";
import { useRemainingMeditations } from "../lib/useRemainingMeditations";

export const MeditationHome = () => {
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const {loading, values, error} = useRecentMeditations(currentUser.uid);
    const [remaining, loadingRemaining] = useRemainingMeditations(currentUser.uid);

    return <div className="max-w-[600px] mx-auto min-h-[100vh]">
        <div className="text-5xl mb-12 font-garamond">Welcome, {currentUser.displayName}.</div>

        {!loadingRemaining && <div>
            <PrimaryButton dark onClick={() => {navigate("/create")}}
                disabled={(remaining === null || remaining <= 0)}>
                <div className="flex flex-row items-center justify-center text-xl py-4">
                    Create a new meditation
                </div>
                
            </PrimaryButton>
            <div className="w-full text-center text-sm font-quicksand font-medium pt-4">
                {remaining ? remaining : 0} remaining for today.
            </div>
        </div>
        }

        

        <div className="w-full text-2xl font-garamond mt-12 mb-4">
            Your past meditations
        </div>

        
        {!loading && values &&
            <div className="w-full flex flex-row flex-wrap">
                {values.map((meditation, index) => 
                    <PreviousMeditation key={index} meditation={meditation} />)}
            </div>
        }
        
        
    </div>
}