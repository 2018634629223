import { useState } from "react"
import { Chip, PrimaryButton } from "../UI"
import { PlayButtonSmall, PlayCircle } from "../icons"
import { VOICE_OPTIONS, VOICE_OPTION_INFO, VoiceOption } from "./MeditationConfig"

export const VoicePicker = (
    {
        voice,
        setVoice,
        onDone
    } : {
        voice: VoiceOption | undefined,
        setVoice: (voiceOption: VoiceOption) => void,
        onDone: () => void,
    }
) => {
    return <div className="flex flex-col items-center space-y-8">
        <div className="w-full text-center text-3xl sm:text-5xl font-garamond">
            Select voice:
        </div>
        <div className="flex flex-col sm:flex-row sm:mx-24 items-center justify-center space-y-8 sm:space-y-0 sm:space-x-8 ">

        {
            VOICE_OPTIONS.map((voiceOption, index) => 
                <VoiceOptionCard
                    key={index}
                    name={VOICE_OPTION_INFO[voiceOption].name}
                    details={VOICE_OPTION_INFO[voiceOption].details}
                    clip={VOICE_OPTION_INFO[voiceOption].clip} 
                    selected={voiceOption === voice}
                    onSelect={() => {setVoice(voiceOption)}}/>
            )
        }
        </div>
    </div>
}

const VoiceOptionCard = (
    {
        name,
        details,
        clip,
        selected,
        onSelect
    } : {
        name: string,
        details: string,
        clip: string,
        selected: boolean,
        onSelect: () => void,
    }
) => {

    const [audio, setAudio] = useState(new Audio(clip));

    return <div className={`flex flex-col space-y-2 p-3 rounded-2xl border border-black
                ${selected ? "bg-black text-white fill-white" : ""}`}>
        <div className="text-2xl">
            {name}
        </div>
        <div className="text-sm">
            {details}
        </div>
        <div className="flex flex-row space-x-2 text-sm items-center cursor-pointer hover:underline"
            onClick={() => {
                audio.play();
            }} >
            <div>
                {PlayButtonSmall}
            </div>
            <div>
                Play Sample
            </div>
        </div>
        <PrimaryButton onClick={onSelect}>
            Select
        </PrimaryButton>
    </div>
}