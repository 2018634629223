import { useCallback, useEffect, useRef, useState } from "react";
import { DURATION_OPTIONS, MEDITATION_CATEGORY_OPTIONS, MeditationCategory, THEME_OPTIONS } from "../MeditationDesigner/MeditationConfig";
import { PrimaryButton } from "../UI";
import { ScriptSample, ScriptSamplesToCSV, createMeditationConfigs, getBatchScripts } from "./batchcompletions";

export const Prompter = () => {

    const [goals, setGoals] = useState<Set<MeditationCategory>>( new Set<MeditationCategory>());
    const [entries, setEntries] = useState<Set<string | null>>( new Set<string | null>());
    const [possibleEntries, setPossibleEntries] = useState<(string | null)[]>([null]);
    const [durations, setDurations] = useState<Set<number>>(new Set<number>());
    const [themes, setThemes] = useState<Set<string | null>>( new Set<string | null>());
    const [samples, setSamples] = useState<ScriptSample[]>([]);
    const [generating, setGenerating] = useState<boolean>(false);
    
    const [newEntry, setNewEntry] = useState<string>("");

    const [numSamples, setNumSamples] = useState<number>(1);

    const downloadRef = useRef(null);

    const totalSamples = numSamples * goals.size * entries.size * durations.size * themes.size

    const generateAllSamples = () => {
        setGenerating(true);
        const configs = createMeditationConfigs(numSamples, goals, entries, durations, themes, "Lawrence");
        getBatchScripts(configs, (scriptSample:ScriptSample) => {
            console.log(scriptSample)
            setSamples(samples => [...samples, scriptSample])
        })
    }

    useEffect(() => {
        if (samples.length >= totalSamples) {
            setGenerating(false);
            const CSV = ScriptSamplesToCSV(samples);
            const encodedUri = encodeURI("data:text/csv;charset=utf-8," + CSV);
            console.log("csv", CSV)

            if (downloadRef && downloadRef.current) {
                (downloadRef.current as any).setAttribute("href", encodedUri);
            }
        }
    }, [samples])

    return <div className="container mx-auto flex flex-col space-y-8">
        <div className="text-7xl font-garamond mt-24">Prompter</div>
        
        <div>
            <div className="text-4xl">Samples: {totalSamples}</div>
            <span>Multiplier: </span><input className="p-2 rounded-md my-2" type="number" value={numSamples} onChange={(e) => {setNumSamples(parseInt(e.target.value))}}/>
        </div>
        
        <div className="my-2">
            {`total samples = ${numSamples} samples * ${goals.size} goals * ${entries.size} entries * ${durations.size} durations * ${themes.size} themes = ${totalSamples}`}
        </div>

        <div className="text-4xl mb-4 mt-12">Parameters</div>
        
        <div className="flex flex-row space-x-16 mb-12">
            <ParameterList 
                name="Goals"
                allOptions={MEDITATION_CATEGORY_OPTIONS}
                selectedOptions={goals}
                updateSelectedOptions={setGoals}/>

            <div className="flex flex-col space-y-2">
                
                <ParameterList
                    name="Entries"
                    allOptions={possibleEntries}
                    selectedOptions={entries}
                    updateSelectedOptions={setEntries} />

                <textarea className="min-w-[300px]"
                    value={newEntry}
                    onChange={(e) => {setNewEntry(e.target.value)}}></textarea>
                
                <div className="underline cursor-pointer hover:font-bold"
                    onClick={() => {
                        setPossibleEntries([...possibleEntries, newEntry]);
                        setNewEntry("");
                    }}>
                    + Add Entry
                </div>
            </div>
                
            <ParameterList
                name="Durations"
                allOptions={DURATION_OPTIONS}
                selectedOptions={durations}
                updateSelectedOptions={setDurations}/>

            <ParameterList
                name="Themes"
                allOptions={[null, ...THEME_OPTIONS]}
                selectedOptions={themes}
                updateSelectedOptions={setThemes}/>
            
        </div>
        
        <PrimaryButton onClick={generateAllSamples} >Create {totalSamples} Scripts</PrimaryButton>
        {generating && <div>Generating...</div>}
        {samples.length > 0 && <div>{`${samples.length} scripts generated`}</div>}
        
        <a ref={downloadRef} download="allSamples.csv">
            Download CSV
        </a>

        <div className="h-48"></div>
        
    </div>
}

export const ParameterList = <T extends unknown>(
    {
        name,
        allOptions,
        selectedOptions,
        updateSelectedOptions,
    } : {
        name: string,
        allOptions: T[],
        selectedOptions: Set<T>,
        updateSelectedOptions: (newSelectedOptions: Set<T>) => void,
    }
) => {

    return <div className="flex flex-col space-y-2">
        <div className="text-xl font-bold">{`${name} (${selectedOptions.size})`}</div>
        <div className="flex flex-col max-h-[60vh] flex-wrap">
            {
                allOptions.map(
                    (option, index) => <ParameterOption
                        key={index}
                        name={"" + option}
                        checked={selectedOptions.has(option)} 
                        onCheck={() => {
                            if (selectedOptions.has(option)) {
                                selectedOptions.delete(option);
                            } else {
                                selectedOptions.add(option);
                            }
                            updateSelectedOptions(new Set(selectedOptions));
                        }}/>
                )
            }
        </div>
    </div>
}

export const ParameterOption = (
    {
        name,
        checked,
        onCheck,
    } : {
        name: string,
        checked: boolean,
        onCheck: (checked:boolean) => void,
    }
) => {
    return <div className="flex flex-row space-x-2">
        <input type="checkbox" checked={checked} onChange={(e) => {onCheck(e.target.checked)}}/>
        <div>{name}</div>
    </div>
}