import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { ChatCompletionRequestMessageRoleEnum, CreateChatCompletionResponse } from "openai";

export const getSingleChatCompletion = async (prompt: string) => {
    
    const openAi = httpsCallable(functions, "openAiCompletion");

    const messages = [{
        role: ChatCompletionRequestMessageRoleEnum.System, 
        content: prompt}];

    try {
        const result = await openAi({messages: messages, model: "gpt-3.5-turbo", temperature: 0.7});
        /** @type {any} */
        const data: any = result.data;
        const apiResponse = data.openApiResponseData as CreateChatCompletionResponse;
        const messageText = apiResponse.choices[0].message?.content;
        
        if (messageText) {
            return messageText;
        }
    }
    catch (error) {
        throw error;
    }
}