import { useContext } from "react";
import { AuthContext } from "../App";
import { useParams } from "react-router-dom";
import { MeditationPlayer } from "../components/MeditationPlayer";

export const Meditate = () => {
    const {currentUser} = useContext(AuthContext);
    const meditationDocId = useParams().entryId;
    
    if (!meditationDocId || !currentUser) return <div>Error</div>;
    
    return <div className="mt-24">
        <MeditationPlayer 
            meditationId={meditationDocId} 
            uid={currentUser.uid}
            onReady={() => {}} />
    </div>
}