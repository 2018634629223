import { useEffect, useState } from "react";
import { BackArrow2, EastArrow, FilledPauseButton, FilledPlayButton, ForwardArrow, PlayButtonSmall, PlayCircle, WestArrow } from "../icons"
import { SettingChip } from "./LandingDemo"
import demo1 from "../../audio/demo1.mp3";
import demo2 from "../../audio/demo2.mp3";
import demo3 from "../../audio/demo3.mp3";

const SLIDE_DATA = [
    {
        name: "David",
        text: "I have a big presentation tomorrow, my mind is racing and I can’t sleep",
        title: "Tranquil Waterfall Journey",
        imageSrc: "/assets/waterfall.png",
        script: <div>
            ...know that you are more than capable, David. You have prepared diligently, honed your skills, and gathered the necessary knowledge...<br/><br/>

            As you breathe in, draw in the universal light and creative energy. 
            As you breathe out, release any doubts or fears.<br/><br/>

            Allow yourself to feel excited and curious about this presentation. 
            Tap into the feelings of accomplishment and satisfaction that creative work brings...<br/><br/>
        </div>,
        goalOptions: ["relax", "focus", "motivation", "sleep"],
        themeOptions: ["Space", "Rainforest", "Home", "Waterfall"],
        durationOptions: ["3 min", "5 min", "7 min", "10 min"],
        timing: "ml-[20%]",
        time: "2:03",
        endTime: "10:23",
        audio: new Audio(demo1)
    },
    {
        name: "Naomi",
        text: "I just feel low energy. I want take a walk outside and enjoy the breeze",
        title: "Shake It Off, Naomi",
        imageSrc: "/assets/soho.png",
        script: <div>
            Naomi as you continue to walk mindfully through the vibrant streets of Soho, notice the kaleidoscope of colors, the unique architecture, and the diverse faces passing by...
        </div>,
        goalOptions: ["sleep", "relax", "gratitude", "walking"],
        themeOptions: ["Brooklyn", "Central Park", "TimesSquare", "Soho NYC"],
        durationOptions: ["3 min", "5 min", "7 min"],
        timing: "ml-[50%]",
        time: "4:03",
        endTime: "7:03",
        audio: new Audio(demo2)
    },
    {
        name: "Sarah",
        text: "Just had a challenging conversation with my partner. I feel drained",
        title: "Ocean of Love",
        imageSrc: "/assets/ocean.png",
        script: <div>
            Visualize yourself stepping into this boat, letting it carry you to the horizon. As you glide across the waters, imagine sending waves of love and compassion to yourself and your partner...
        </div>,
        goalOptions: ["Soothe", "Sleep", "Anxiety", "Compassion"],
        themeOptions: ["Garden", "Cityscape", "Space", "Ocean"],
        durationOptions: ["3 min", "5 min"],
        timing: "ml-[30%]",
        time: "2:41",
        endTime: "4:45",
        audio: new Audio(demo3)
    }
]

export const LandingDeepDive = () => {

    const [slideIndex, setSlideIndex] = useState(0);
    const [hideAll, setHideAll] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    
    const ANIMATE_STYLE = " transition-all duration-1000 " + (hideAll ? "opacity-0 scale-105" : "opacity-100");
    
    useEffect(() =>{
        if (isPlaying) {
            SLIDE_DATA[slideIndex].audio.play();
        } else {
            SLIDE_DATA[slideIndex].audio.pause();
        }
    }, [isPlaying])

    return <div className="w-full flex flex-col sm:flex-row items-start justify-between text-white border-white fill-white">
        <div className="w-full sm:w-2/5 flex flex-col space-y-4 ">
            <div className="font-bold">
                How are you doing?
            </div>
            <div className={`w-full border p-4 text-2xl rounded-md`} >
                <div className={ANIMATE_STYLE}>
                    <span >{SLIDE_DATA[slideIndex].text}</span><span className="blinking-cursor">|</span>
                </div>
            </div>
            <SettingsRow 
                goalOptions={SLIDE_DATA[slideIndex].goalOptions}
                themeOptions={SLIDE_DATA[slideIndex].themeOptions}
                durationOptions={SLIDE_DATA[slideIndex].durationOptions}
                animate={!hideAll}
                />
            <div className="font-bold">
                This {SLIDE_DATA[slideIndex].durationOptions[SLIDE_DATA[slideIndex].durationOptions.length - 1]} minute meditation was crafted for {SLIDE_DATA[slideIndex].name}.
            </div>
            <div className={`text-sm italic ${ANIMATE_STYLE}`}>
                {SLIDE_DATA[slideIndex].script}
            </div>
        </div>
        <div className="w-full sm:w-1/2  flex flex-row items-center space-x-8">
            <div className="cursor-pointer" onClick={() => {
                            setHideAll(true);
                            setTimeout(() => {
                                setSlideIndex(slideIndex === 0 ? SLIDE_DATA.length - 1 : slideIndex - 1);
                                SLIDE_DATA[slideIndex].audio.pause();
                                setHideAll(false);
                            }, 1000)
                        }}>
                        {WestArrow}
                    </div>
        
            <div className="flex flex-col items-center space-y-6 border rounded-xl p-8">
                <div>PREVIEW</div>
                <div className={`group w-1/2 rounded-xl overflow-hidden relative bg-black cursor-pointer ${ANIMATE_STYLE}`}>
                    <img className="group-hover:opacity-80 opacity-100 transition-opacity duration-500" src={SLIDE_DATA[slideIndex].imageSrc}></img>
                    <div className="absolute fill-white z-30 m-auto top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                        onClick={() => {
                            setIsPlaying(!isPlaying);
                        }}>
                        {isPlaying ? FilledPauseButton : FilledPlayButton}
                    </div>
                </div>
                    
                
                <div className="flex flex-col items-center">
                    <div className={`font-semibold italic text-2xl ${ANIMATE_STYLE}`}>{SLIDE_DATA[slideIndex].title}</div>
                    <div className={`text-md italic ${ANIMATE_STYLE}`}>For {SLIDE_DATA[slideIndex].name}</div>
                </div>
                <div className="w-full">
                    <div className="h-[1px] bg-white/70 rounded-full"/>
                    <div className={`${ANIMATE_STYLE} h-[4px] bg-white/70 rounded-full w-[100px] -mt-[2.5px] ${SLIDE_DATA[slideIndex].timing}`} />
                    
                    <div className="w-full flex flex-row justify-between">
                        <div className={ANIMATE_STYLE}>
                            {SLIDE_DATA[slideIndex].time}
                        </div>
                        <div className={ANIMATE_STYLE}>
                            {SLIDE_DATA[slideIndex].endTime}
                        </div>
                    </div>
                </div>
                
            </div>


            <div className="cursor-pointer"
                        onClick={() => {
                            setHideAll(true);
                            setTimeout(() => {
                                setSlideIndex((slideIndex + 1) % SLIDE_DATA.length);
                                setIsPlaying(false);
                                SLIDE_DATA[slideIndex].audio.pause();
                                setHideAll(false);
                            }, 1000)
                        }}>
                        {EastArrow}
                    </div>
        </div>
        
    </div>
}


export const SettingsRow = (
    {
        goalOptions,
        themeOptions,
        durationOptions,
        animate,
    } : {
        goalOptions: string[],
        themeOptions: string[],
        durationOptions: string[],
        animate: boolean
    }
) => {
    return <div className="w-full flex flex-col sm:space-x-2 space-y-2 sm:space-y-0 sm:flex-row justify-between">
    <SettingChip
        key={0}
        label="Goal"
        options={goalOptions}
        delay={0}
        start={animate} />
    
    {themeOptions.length > 0 && <SettingChip 
        key={1}
        label="Setting" 
        options={themeOptions}
        delay={500}
        start={animate}/>} 
    
    <SettingChip 
        key={2}
        label="Duration" 
        options={durationOptions}
        delay={1000}
        start={animate}/>
</div>
}