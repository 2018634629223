import { ScrollingBackgroundGradient } from "../components/Gradient/ScrollingBackgroundGradient"
import { LandingDemo } from "../components/LandingPage/LandingDemo"
import { LandingHero } from "../components/LandingPage/LandingHero"
import {LandingFeatures} from "../components/LandingPage/LandingFeatures"
import { LandingFooter } from "../components/LandingPage/LandingFooter"
import LandingFAQ from "../components/LandingPage/LandingFAQ"
import { SimpleBackgroundGradient } from "../components/Gradient/SimpleBackgroundGradient"
import { LandingNavbar } from "../components/LandingPage/LandingNavbar"
import { LandingHero2 } from "../components/LandingPage/LandingHero2"
import { LandingDeepDive } from "../components/LandingPage/LandingDeepDive"

export const LandingPage = () => {

    return <div className="relative w-full overflow-hidden font-raleway ">
        <LandingNavbar />
        <SimpleBackgroundGradient />
        <div className="relative max-w-[1200px] mx-auto flex flex-col items-center justify-center mt-36 space-y-24 z-10 px-4 sm:px-0">
            
                <LandingHero2 />
                <LandingDeepDive />
                
                <LandingFeatures/>
                <LandingFAQ />
                <div className="h-20"></div>
                <LandingFooter />

        </div>
    </div>
}