import { useContext, useState } from "react";
import { AuthContext } from "../App";
import { PrimaryButton } from "../components/UI";
import { createMeditation } from "../lib/useMeditation";
import { Meditation, SegmentType } from "../components/Meditation";
import { DURATION_OPTIONS, MeditationCategory, MeditationConfig, VoiceOption } from "../components/MeditationDesigner/MeditationConfig";
import { httpsCallable } from "firebase/functions";
import { db, functions } from "../firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { getSingleChatCompletion } from "../lib/GPTAPI";

export const FunctionsTest = () => {

    const { currentUser } = useContext(AuthContext);
    const [meditation, setMeditation] = useState<Meditation | undefined>(undefined);
    const [meditationDoc, setMeditationDoc] = useState<string | undefined>(undefined);
    const [value, loading, error] = useDocumentData(meditationDoc ? doc(db, "users", currentUser.uid,  "meditations", meditationDoc) : undefined);

    const newMeditation = () => {
        
        let config:MeditationConfig = {
            uid: currentUser.uid,
            username: "Lawrence",
            category: MeditationCategory.GRATITUDE,
            duration: 5,
            inputText: "testing " + Math.trunc(Math.random() * 1000),
            voice: VoiceOption.Saguna,
            theme: null,
        }

        let m:Meditation = {
            uid: currentUser.uid,
            title: "Test Meditation",
            segments: [
                {
                    type: SegmentType.SPEECH,
                    text: "This is a test meditation " + config.inputText,
                },
                {
                    type: SegmentType.PAUSE,
                    factor: 1,
                },
                {
                    type: SegmentType.SPEECH,
                    text: "This is the second segment",
                }
            ],
                
            config: config,
        }

        setMeditation(m);
    }
    console.log("hello world")

    const createDoc = () => {
        if (meditation) {
            createMeditation(currentUser.uid, meditation)
                .then((doc) => {
                    console.log(doc);
                    setMeditationDoc(doc.id)
                });
        }
    }

    const generateMeditation = () => {
        console.log("generating meditation");
        const generate = httpsCallable(functions, "generateMeditationAudio");
        generate({ meditationDocId: meditationDoc, uid: currentUser.uid })
            .catch((error) => {
                console.log(error);
            });
    }

    const chatCompletion = () => {
        getSingleChatCompletion("testing, are you alive")
            .then((result) => {
                console.log(result);
            });
    }

    return <div className="w-[1200px] mt-48 flex flex-col space-y-4">
        <div className="text-2xl">Meditation</div>
        <div className="text-sm">
            {meditation && JSON.stringify(meditation)}</div>

        
        <PrimaryButton onClick={newMeditation}>New Meditation</PrimaryButton>
        <div className="text-sm">
            {meditationDoc && meditationDoc}</div>
        
        <div className="text-sm">
            {value && JSON.stringify(value)}</div>
        <PrimaryButton onClick={createDoc}>Create Meditation Doc</PrimaryButton>

        <PrimaryButton onClick={generateMeditation}>Generate Meditation</PrimaryButton>
        <PrimaryButton onClick={chatCompletion}>chat completion</PrimaryButton>
    </div>
}